import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ element: Component }) {
    const token = localStorage.getItem('jwtToken'); // Asegúrate de que el nombre de la clave coincida con cómo estás almacenando el token
    const location = useLocation();

    if (!token) {
        // Usuario no autenticado, redirigir al login.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return Component;
}

export default PrivateRoute;
