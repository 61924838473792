import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useMenu } from "../contexts/MenuContext";
import AdminMenu from "../data/AdminMenu.json";
import PTPadminLogo from "../assets/media/logos/ptp_admin_v2.png";

function Header() {
  const { userData } = useContext(UserContext);
  const { handleMenuChange, activeMenu, handleSubmenuChange, activeSubmenu } = useMenu();
  const [menus, setMenus] = useState([]);

  /*
  useEffect(() => {
    // Menú original, adaptado de la variable global/Si el AdminMenu no es exportado directamente establece rutas de importación dinámica
    setMenus(AdminMenu.menus);
  }, []);
  */
  useEffect(() => {
    // Filtrar menús y submenús basados en group_id_access
    const filteredMenus = AdminMenu.menus.filter(menu => !menu.group_id_access || menu.group_id_access.includes(userData.group_id)).map(menu => ({
      ...menu,
      submenus: menu.submenus.filter(submenu => !submenu.group_id_access || submenu.group_id_access.includes(userData.group_id))
    }));

    setMenus(filteredMenus);
  }, [userData.group_id]); // Re-calcular cuando el group_id del usuario cambie

  const getSubmenus = () => {
    const active = menus.find(menu => menu.name === activeMenu);
    return active ? active.submenus : [];
  };

  return (
    <div id="kt_app_header" className="app-header">
      {/* Código de despliegue de menús y submenús adaptado según es necesario */}
      <div className="app-header-primary">
        <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_primary_container">
          <div className="d-flex flex-stack flex-grow-1">
            <div className="d-flex">
              <div className="app-header-logo d-flex flex-center gap-2 me-lg-15">
                <button className="btn btn-icon btn-sm btn-custom d-flex d-lg-none ms-n2" id="kt_app_header_menu_toggle">
                  <i className="ki-outline ki-abstract-14 fs-2"></i>
                </button>
                <Link to="/dashboard">
                  <img alt="Logo" src={PTPadminLogo} className="mh-25px" />
                </Link>
              </div>
              <div className="d-flex align-items-stretch" id="kt_app_header_menu_wrapper">
                <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}">
                  <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-gray-900 menu-icon-gray-500 menu-arrow-gray-500 menu-state-icon-primary menu-state-bullet-primary fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0" id="#kt_app_header_menu" data-kt-menu="true">
                    {menus.map(menu => (
                      <div key={menu.name} className={`menu-item menu-principal menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 ${activeMenu === menu.name ? "active" : ""}`}  onClick={() => handleMenuChange(menu.name)}>
                        <Link to={menu.link} className="menu-link">
                          <span className="menu-title">{menu.name}</span>
                          <span className="menu-arrow d-lg-none"></span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Barra de submenús: podría ajustarse según se requiera o manejada en otro componente */}
      <div className="app-header-secondary app-header-mobile-drawer" >
	  	<div className="d-flex flex-column flex-grow-1 overflow-hidden">
			<div className="app-header-secondary-menu-main d-flex flex-grow-lg-1 align-items-end pt-3 pt-lg-2 px-3 px-lg-0 w-auto overflow-auto flex-nowrap">
				<div className="app-container container-fluid">
					<div className="menu menu-rounded menu-nowrap flex-shrink-0 menu-row menu-active-bg menu-title-gray-700 menu-state-gray-900 menu-icon-gray-500 menu-arrow-gray-500 menu-state-icon-primary menu-state-bullet-primary fw-semibold fs-base align-items-stretch" id="#kt_app_header_secondary_menu" data-kt-menu="true">
					{activeMenu && getSubmenus().map(submenu => (
						<div className="menu-item menu-secundario">
							<Link key={submenu.name} to={submenu.link} className={`menu-link ${activeSubmenu === submenu.name ? "active" : ""}`} onClick={() => handleSubmenuChange(submenu.name)}>
								{submenu.name}
							</Link>
						</div>
					))}
				</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
