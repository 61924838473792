import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { useMenu } from "../contexts/MenuContext";
import AdminMenu from "../data/AdminMenu.json"; 
import { UserContext } from "../contexts/UserContext";

function Aside(){

	const { userData } = useContext(UserContext); // Obtener datos del usuario
    const { activeMenu, activeSubmenu, activeAsideItem } = useMenu();

    // Encuentra el submenú activo basado en el menú y submenú activo
    const activeSubmenuData = AdminMenu.menus
        .find(menu => menu.name === activeMenu)?.submenus
        .find(submenu => submenu.name === activeSubmenu);

    // Aquí es donde aplicarás el filtro basado en group_id_access
    // Asegurándote de que asideItems solo contenga elementos accesibles por el group_id del usuario
    const asideItems = activeSubmenuData && activeSubmenuData.aside 
        ? activeSubmenuData.aside.filter(item => !item.group_id_access || item.group_id_access.includes(userData.group_id))
        : [];

    return(
        <div id="kt_app_sidebar" className="app-sidebar" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
						{/* begin::Sidebar wrapper */}
						<div id="kt_app_sidebar_wrapper" className="flex-grow-1 hover-scroll-y mt-9 mb-5 px-2 mx-3 ms-lg-7 me-lg-5">
							
							{/* begin::Main menu */}
							<div className="menu-sidebar menu menu-fit menu-column menu-rounded menu-title-gray-700 menu-icon-gray-700 menu-arrow-gray-700 fw-semibold fs-6 align-items-stretch flex-grow-1" id="#kt_app_sidebar_menu" >
								
								{/* begin:Menu item */}
								<div data-kt-menu-trigger="click" className="menu-item menu-accordion show">
									{/* begin:Menu link 
									<span className="menu-link">
										<span className="menu-title">Contextual</span>
										<span className="menu-arrow"></span>
									</span>
									 end:Menu link */}

									{/* begin:Menu sub AQUÍ VAL EL TERCER NIVEL DEL MENÚ */}
									<div className="menu-sub menu-sub-accordion menu-state-gray-900 menu-fit open menu-tercero-aside">

										{asideItems.map((item, index) => (
											<div key={index} className={`menu-item menu-accordion menu-fit ${activeAsideItem === item.name ? "active" : ""}`}>
												<span className="menu-link" >
													<Link to={item.link} className="menu-link">
														<span className="menu-icon">
															<i class="ki-outline ki-right fs-4 text-gray-700"></i>
														</span>
														<span className="menu-title">{item.name}</span>
													</Link>
												</span>
											</div>
										))}

									</div>
									{/* end:Menu sub */}
								</div>
								{/* end:Menu item */}
								
								{/* begin:Menu item */}
								<div className="menu-item py-1">
									{/* begin:Menu content */}
									<div className="menu-content">
										<div className="separator separator-dashed"></div>
									</div>
									{/* end:Menu content */}
								</div>
								{/* end:Menu item */}
								
								{/* begin:Menu item */}
								<div data-kt-menu-trigger="click" className="menu-item menu-accordion show">
									{/* begin:Menu link */}
									<span className="menu-link">
										<span className="menu-title">CRM</span>
										<span className="menu-arrow"></span>
									</span>
									{/* end:Menu link */}
									{/* begin:Menu sub */}
									<div className="menu-sub menu-sub-accordion menu-state-gray-900 menu-fit open">
										{/* begin:Menu item */}
										<div className="menu-item menu-accordion menu-fit">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-element-11 fs-4 text-gray-700"></i>
												</span>
												<span className="menu-title">Grid</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
										{/* begin:Menu item */}
										<div className="menu-item">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-subtitle fs-4 text-danger"></i>
												</span>
												<span className="menu-title">Form</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
										{/* begin:Menu item */}
										<div className="menu-item">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-calendar fs-4 text-success"></i>
												</span>
												<span className="menu-title">Calendar</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_bidding">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
										{/* begin:Menu item */}
										<div className="menu-item">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-picture fs-4 text-info"></i>
												</span>
												<span className="menu-title">Gallery</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
										{/* begin:Menu item */}
										<div className="menu-item">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-element-2 fs-4 text-warning"></i>
												</span>
												<span className="menu-title">Kanban</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_top_up_wallet">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
										{/* begin:Menu item */}
										<div className="menu-item">
											{/* begin:Menu link */}
											<span className="menu-link">
												<span className="menu-icon">
													<i className="ki-outline ki-abstract-44 fs-4 text-danger"></i>
												</span>
												<span className="menu-title">Timeline</span>
												<span className="menu-badge">
													<button className="btn btn-sm btn-icon btn-action" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">
														<i className="ki-outline ki-plus fs-4"></i>
													</button>
												</span>
											</span>
											{/* end:Menu link */}
										</div>
										{/* end:Menu item */}
									</div>
									{/* end:Menu sub */}
								</div>
								{/* end:Menu item */}
								{/* begin:Menu item */}
								<div className="menu-item py-1">
									{/* begin:Menu content */}
									<div className="menu-content">
										<div className="separator separator-dashed"></div>
									</div>
									{/* end:Menu content */}
								</div>
								{/* end:Menu item */}

								{/* begin:Menu item */}
								<div className="menu-item menu-accordion menu-fit">
									{/* begin:Menu link */}
									<Link to="/logout" className="menu-link">
										<span className="menu-icon">
											<i className="ki-outline ki-black-left-line fs-4 text-gray-700"></i>
										</span>
										<span className="menu-title">Salir</span>
									</Link>
									{/* end:Menu link */}
								</div>
								{/* end:Menu item */}
								
							</div>
							{/* end::Menu */}
						</div>
						{/* end::Sidebar wrapper */}
					</div>
    )


}

export default Aside;