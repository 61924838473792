import React from "react";
import LayoutMain from '../../components/LayoutMain';

function BasesPage(){


    return(
        <LayoutMain title="Bases">
        Bases
        </LayoutMain>
    )

}

export default BasesPage;