import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import '../assets/css/CustomEditor.css';

const CustomEditor = ({ value, onChange, label }) => {
    const editor = useRef(null);
    
    const config = useMemo(() => ({
        readonly: false,
        height: 500,
        language: 'es',
        toolbarAdaptive: false,
        buttons: [
            'source', '|',
            'bold', 'strikethrough', 'underline', 'italic', '|',
            'ul', 'ol', '|',
            'outdent', 'indent', '|',
            'font', 'fontsize', 'brush', 'paragraph', '|',
            'table', 'link', 'image', '|',
            'align', 'undo', 'redo', '|',
            'hr', 'eraser', 'copyformat', '|',
            'fullsize'
        ],
        uploader: {
            insertImageAsBase64URI: true
        },
        link: {
            openInNewTabCheckbox: true
        },
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarSticky: false,
        removeButtons: ['about'],
        statusbar: false,
        theme: 'default',
        enableDragAndDropFileToEditor: true,
        showXPathInStatusbar: false
    }), []); // Array vacío significa que el config nunca cambiará

    return (
        <div className="fv-row mb-7">
            {label && (
                <label className="fs-6 fw-semibold form-label mt-3">{label}</label>
            )}
            <div style={{ minHeight: '500px' }}> {/* Contenedor con altura mínima fija */}
                <JoditEditor
                    ref={editor}
                    value={value}
                    config={config}
                    onBlur={onChange}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default CustomEditor;