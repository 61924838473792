import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import LayoutMain from '../../components/LayoutMain';
import { UserContext } from '../../contexts/UserContext';

const MigracionUpdatePasswordsPage = () => {
    const token = localStorage.getItem('jwtToken');
    const { userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleMigration = async () => {
        setIsLoading(true);
        setMessage('');
        setError('');

        try {
            const response = await axios.post('https://api.ptpbizintelligence.com/api/web/admin/update-passwords-toponline', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.success) {
                setMessage(response.data.message);
            } else {
                setError('Error al actualizar los passwords.');
            }
        } catch (err) {
            console.error('Hubo un error al actualizar los passwords:', err);
            setError('Hubo un error al actualizar los passwords.');
        }

        setIsLoading(false);
    };

    return (
        <LayoutMain>
            <Helmet>
                <title>Migración de Passwords</title>
            </Helmet>
            
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    <div className="card">
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3>Top Online - Migración de Passwords</h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            <button onClick={handleMigration} className="btn btn-primary mb-2" disabled={isLoading}>
                                {isLoading ? 'Actualizando...' : 'Migrar Passwords'}
                            </button>
                            {message && <p className="text-success">{message}</p>}
                            {error && <p className="text-danger">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutMain>
    );
};

export default MigracionUpdatePasswordsPage;
