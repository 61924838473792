import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { UserContext } from '../../contexts/UserContext'; 
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';

// Función para manejar el formateo de la fecha
  const formatDate = (date) => {
	return format(date, 'dd/MM/yyyy');
  };

function UsuariosPage() {

	const token = localStorage.getItem('jwtToken');
	const {userData,setUserData} = useContext(UserContext);
    const [usuarios, setUsuarios] = useState([]);
	const [search, setSearch] = useState("");
    const [selectedUserGroup, setSelectedUserGroup] = useState("todos");
    const [gruposUsuarios, setGruposUsuarios] = useState([]);
    // Asegúrate de tener estados para las fechas de inicio y fin si vas a implementar el filtrado por fechas
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalUsuarios, setTotalUsuarios] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);

	function FormattedDate({ dateString }) {
		const date = new Date(dateString);
		const formattedDate = format(date, 'dd/MM/yyyy'); // Formato día/mes/año
	  
		return <span>{formattedDate}</span>;
	  }

	  

	 // Este useEffect se encarga de cargar los grupos de usuarios al cargar el componente
	 useEffect(() => {
        axios.get('https://api.ptpbizintelligence.com/api/web/admin/minas/minasgetusergroups',{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		})
            .then(response => {
                // Suponiendo que la respuesta es un array de grupos
                setGruposUsuarios(response.data);
            })
            .catch(error => {
                console.error('Error al obtener los grupos de usuarios:', error);
            });
    }, []);

    useEffect(() => {
		// Llamar a fetchUsuarios solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if (search.length > 2 || selectedUserGroup || startDate || endDate) {
			fetchUsuarios();
		}
	}, [search, selectedUserGroup, startDate, endDate, paginaActual, itemsPorPagina]);

	const fetchUsuarios = async () => {
		let queryParams = {
			page: paginaActual,
			'per-page': itemsPorPagina,
		};
	
		if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
		if (selectedUserGroup !== 'todos') queryParams.usergroupId = selectedUserGroup; // No incluir si es 'todos'
		if (startDate) queryParams.startDate = startDate.toISOString().split('T')[0]; // Convertir fecha de inicio a ISO si está presente
		if (endDate) queryParams.endDate = endDate.toISOString().split('T')[0]; // Convertir fecha de fin a ISO si está presente
	
		const queryString = new URLSearchParams(queryParams).toString();
		const url = `https://api.ptpbizintelligence.com/api/web/admin/minas/minasgetusers?${queryString}`;
	
		try {
			const response = await axios.get(url,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
			});
			setUsuarios(response.data.usuarios);
			setTotalUsuarios(response.data.total);
			setTotalPaginas(response.data.totalPages);
		} catch (error) {
			console.error('Hubo un error al obtener los datos de los usuarios:', error);
		}
	};
	

    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
		// Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}
		
	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalUsuarios ? totalUsuarios : fin; // Asegurarse de no superar el total de usuarios
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalUsuarios) {
			fin = totalUsuarios;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalUsuarios > fin && <>de <b>{totalUsuarios}</b></>} usuarios
			</div>
			)
	};

	const confirmDelete = (userId) => {
		setSelectedUserId(userId);
		setShowDeleteConfirm(true);
		console.log('confirmDelete:',userId);
	  };
	  
	const deleteUser = async () => {
		setShowDeleteConfirm(false);
		// Aquí iría el código para llamar a la API y eliminar el usuario
		try {
		await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/minas/minasdeleteuser/${selectedUserId}`,{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		});
		toast.success('Usuario eliminado con éxito');
		fetchUsuarios();
		// Luego de eliminar, podrías recargar la lista de usuarios o quitar el usuario de la lista en el estado
		} catch (error) {
		toast.error('Error al eliminar el usuario');
		}
	};

	
	  
	return(
        <LayoutMain title="Usuarios">
			<ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteUser()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este usuario.
			</SweetAlert>
			)}
            {/* begin::Toolbar */}
            	<div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
					{/* begin::Toolbar container */}
					<div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
						{/* begin::Toolbar wrapper */}
						<div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
							{/* begin::Page title */}
							<div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
								{/* begin::Title */}
								<h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Usuarios</h1>
								{/* end::Title */}
								{/* begin::Breadcrumb */}
								<ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Minas</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Usuarios</li>
									{/* end::Item */}
								</ul>
								{/* end::Breadcrumb */}
							</div>
							{/* end::Page title */}
							{/* begin::Actions */}
							<div  className="d-flex align-items-center gap-2 gap-lg-3">
								{/*
								<a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
								<a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
								<i  className="ki-outline ki-eye fs-4"></i>Preview</a>
								*/}
								{userData.group_id != 4 && (
								<>
									<Link to="/minas-usuario"  className="btn btn-sm btn-flex btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">
									<i  className="ki-outline ki-user fs-4"></i>Agregar Usuario</Link>
								</>
								)}
							</div>
							{/* end::Actions */}
						</div>
						{/* end::Toolbar wrapper */}
					</div>
					{/* end::Toolbar container */}
				</div>
				{/* end::Toolbar */}
				{/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							{/* begin::Card header */}
							<div  className="card-header border-0 pt-6">
								{/* begin::Card title */}
								<div  className="card-title">
									{/* begin::Search */}
									<div  className="d-flex align-items-center position-relative my-1">
										<i  className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
										<input type="text" data-kt-customer-table-filter="search"  className="form-control form-control-solid w-350px ps-12 tablaBuscador" placeholder="Buscar Nombre,Email,DNI,RUC,Empresa"  value={search} onChange={(e) => setSearch(e.target.value)} />
									</div>
									{/* end::Search */}
									{/* begin::Grupos */}
									<div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
										<select className="form-control form-control-solid w-150px selectUsuarios" value={selectedUserGroup} onChange={(e) => setSelectedUserGroup(e.target.value)}>
											<option value="todos">Todos</option>
											{gruposUsuarios.map((grupo) => (
												<option key={grupo.usergroup_id} value={grupo.usergroup_id}>{grupo.usergroup_name}</option>
											))}
										</select>
									</div>
									{/* end::Grupos */}
								</div>
								{/* begin::Card title */}
								{/* begin::Card toolbar */}
								<div className="card-toolbar">
									{/* Campos de fecha Desde y Hasta */}
									<div className="d-flex align-items-center gap-2">
										<DatePicker
											selected={startDate}
											onChange={(date) => setStartDate(date)}
											dateFormat="dd/MM/yyyy"
											isClearable
      										placeholderText="Desde"
											className="tableDatetimeInput"
											/>
										<DatePicker
											selected={endDate}
											onChange={(date) => setEndDate(date)}
											dateFormat="dd/MM/yyyy"
											isClearable
      										placeholderText="Desde"
											className="tableDatetimeInput"
											/>
										</div>
									</div>
								{/* end::Card toolbar */}
							</div>
							{/* end::Card header */}
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
											<th  className="min-w-125px">RUC/DNI</th>
											<th  className="min-w-125px">Nombre completo</th>
											<th  className="min-w-125px">Email</th>
											<th  className="min-w-125px">Empresa</th>
											<th  className="min-w-125px">Inicio de membresía</th>
											<th  className="min-w-125px">Fin de membresía</th>
											<th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{usuarios.map((usuario, index) => (
											<tr key={index}>
												<td>{usuario.ruc_dni}</td>
												<td>{usuario.name}</td>
												<td>{usuario.email}</td>
												<td>{usuario.empresa}</td>
												<td><FormattedDate dateString={usuario.inicio} /></td>
												<td><FormattedDate dateString={usuario.fin} /></td>
												<td>
													{userData.group_id != 4 && (
													<>
														<Link to={`/minas-usuario/${usuario.user_id}`}  className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar"><i class="ki-outline ki-notepad-edit"></i></Link>
														<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(usuario.user_id)}><i class="ki-outline ki-trash"></i></button>
													</>
													)}
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>
        </LayoutMain>                    
    )
}

export default UsuariosPage;