import React from "react";
import LayoutMain from '../../components/LayoutMain';

function NotificacionesPage(){


    return(
        <LayoutMain title="Notificaciones">
        Notificaciones
        </LayoutMain>
    )

}

export default NotificacionesPage; 