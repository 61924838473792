import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

function EmpresaInformacion() {
  const { rucDni } = useParams();
  const [usuarios, setUsuarios] = useState([]);
  const [descargas, setDescargas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [rucDni]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('jwtToken');
      const usuariosResponse = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/toponlinegetusuariosempresa/${rucDni}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsuarios(usuariosResponse.data || []);

      const descargasResponse = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/toponlinegetdescargasempresa/${rucDni}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDescargas(descargasResponse.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Hubo un error al cargar los datos. Por favor, intente de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LayoutMain title="Cargando..."><div>Cargando datos...</div></LayoutMain>;
  }

  if (error) {
    return <LayoutMain title="Error"><div>{error}</div></LayoutMain>;
  }

  const nombreEmpresa = usuarios.length > 0 ? usuarios[0].empresa : 'Empresa desconocida';

  return (
    <LayoutMain title={`Información de ${nombreEmpresa}`}>
      {/* begin::Toolbar */}
      <div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
        <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
          <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                {`${nombreEmpresa} (${rucDni})`}
              </h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                <li className="breadcrumb-item text-muted">Top Online</li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-500 w-5px h-2px"></span>
                </li>
                <li  className="breadcrumb-item text-muted">
                    <Link to="/toponline-usuarios">Usuarios</Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-500 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-muted">Información de Empresa</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* end::Toolbar */}

      {/* begin::Content */}
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-fluid">
          {/* Tabla de Usuarios */}
          <div className="card mb-5">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <h2>Usuarios de la Empresa</h2>
              </div>
            </div>
            <div className="card-body pt-0">
              {usuarios.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
                    <thead>
                      <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-125px">ID</th>
                        <th className="min-w-125px">Nombre completo</th>
                        <th className="min-w-120px">Email</th>
                        <th className="min-w-80px">Teléfono</th>
                        <th className="min-w-100px">Cargo</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      {usuarios.map((usuario) => (
                        <tr key={usuario.user_id}>
                          <td>{usuario.user_id}</td>
                          <td>{usuario.name}</td>
                          <td>{usuario.email}</td>
                          <td>{usuario.telefono}</td>
                          <td>{usuario.cargo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No hay usuarios registrados para esta empresa.</div>
              )}
            </div>
          </div>

          {/* Tabla de Descargas */}
          <div className="card">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <h2>Descargas de la Empresa</h2>
              </div>
            </div>
            <div className="card-body pt-0">
              {descargas.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin">
                    <thead>
                      <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-80px">Usuario ID</th>
                        <th className="min-w-120px">Fecha Creación</th>
                        <th className="min-w-150px">Nombre Archivo</th>
                        <th className="min-w-100px">Base</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      {descargas.map((descarga) => (
                        <tr key={descarga.descarga_id}>
                          <td>{descarga.user_id}</td>
                          <td>{format(new Date(descarga.fecha_creacion), 'dd/MM/yyyy', { locale: es })}</td>
                          <td>{descarga.nombre_archivo}</td>
                          <td>{descarga.base}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No hay descargas registradas para esta empresa.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end::Content */}
    </LayoutMain>
  );
}

export default EmpresaInformacion;