import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import PaisesLista from '../../data/paises.json';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Función para manejar el formateo de la fecha
  const formatDate = (date) => {
	return format(date, 'dd/MM/yyyy');
  };

function UsuarioPage() {

	const token = localStorage.getItem('jwtToken');
	const { userId } = useParams(); // Obtiene el ID del usuario de la URL
	const [usuario, setUsuario] = useState(null);
	const [inicioMembresia,setInicioMembresia] = useState(null);
	const [finMembresia,setFinMembresia] = useState(null);
	const [selectedUserGroup, setSelectedUserGroup] = useState(null);
	const [gruposUsuarios, setGruposUsuarios] = useState([]);
	const [kioskoRoles,setKioskoRoles] = useState([]);
	const [selectedKioskoRol,setSelectedKioskoRol] = useState(null);
	const [selectedUniversidad,setSelectedUniversidad] = useState(0);
	const [selectedPais,setSelectedPais] = useState("Peru");

	const [nombre, setNombre] = useState('');
	const [rucDni, setRucDni] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [email, setEmail] = useState('');
	const [telefono, setTelefono] = useState('');
	const [notas, setNotas] = useState('');

	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const [isSubmitting, setIsSubmitting] = useState(false);


	function FormattedDate({ dateString }) {
		const date = new Date(dateString);
		const formattedDate = format(date, 'dd/MM/yyyy'); // Formato día/mes/año
	
		return <span>{formattedDate}</span>;
	}


  // Este useEffect se encarga de cargar los roles del kiosko al componente
  useEffect(() => {
        axios.get('https://api.ptpbizintelligence.com/api/web/admin/pymes/pymesgetkioskoroles',{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		})
            .then(response => {
                // Suponiendo que la respuesta es un array de roles
                console.log('kiosko roles:',response.data);
                setKioskoRoles(response.data);
            })
            .catch(error => {
                console.error('Error al obtener los grupos de usuarios:', error);
            });
    }, []);

    // Este useEffect se encarga de cargar los grupos de usuarios al cargar el componente
  useEffect(() => {
        axios.get('https://api.ptpbizintelligence.com/api/web/admin/pymes/pymesgetusergroups',{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		})
            .then(response => {
                // Suponiendo que la respuesta es un array de grupos
                setGruposUsuarios(response.data);
            })
            .catch(error => {
                console.error('Error al obtener los grupos de usuarios:', error);
            });
    }, []);

  useEffect(() => {
    if (userId) {
      // Si hay un ID de usuario, estamos en modo de edición
      // Llamar a la API para obtener los datos del usuario y cargarlos en el formulario
      axios.get(`https://api.ptpbizintelligence.com/api/web/admin/pymes/pymesuser/${userId}`,{
		headers: {
			Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
		},
	  })
        .then(response => {
            // Suponiendo que la respuesta es un array de grupos
            console.log('pymes user:',response.data);
            const usuario = response.data.datos;

            setNombre(usuario.name);
            setRucDni(usuario.ruc_dni);
            setEmpresa(usuario.empresa);
            setEmail(usuario.email);
            setTelefono(usuario.telefono);
            setNotas(usuario.notas);

            setSelectedPais(usuario.pais);
            setSelectedUserGroup(response.data.group_id);
            setSelectedUniversidad(usuario.universidad);
            setSelectedKioskoRol(usuario.kioskorol);
            setInicioMembresia(usuario.inicio);
            setFinMembresia(usuario.fin);
        })
        .then((data) => setUsuario(data));
    }
  }, [userId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
		setIsSubmitting(true); // Deshabilitar el botón al iniciar el envío

        if (password !== passwordConfirm) {
            toast.error('Las contraseñas no coinciden');
            return; // Detener la ejecución si las contraseñas no coinciden
        }

        const userData = {
            name: nombre,
            password: event.target.password.value,  // Asumiendo que mantienes el manejo de las contraseñas de esta manera
            email: email,
            group_id: selectedUserGroup,
            ruc_dni: rucDni,
            empresa: empresa,
            telefono: telefono,
            inicio: formatDate(inicioMembresia),
            fin: formatDate(finMembresia),
            universidad: selectedUniversidad,
            pais: selectedPais,
            kioskorol: selectedKioskoRol,
            notas: notas,
            password: password ? password : undefined,
        };


        const endpoint = userId 
          ? `https://api.ptpbizintelligence.com/api/web/admin/pymes/pymesuser/${userId}` // Endpoint de actualización
          : 'https://api.ptpbizintelligence.com/api/web/admin/pymes/pymescreateuser'; // Endpoint de creación
  
        const method = userId ? 'put' : 'post';

        try {
            const response = await axios[method](endpoint, userData,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
			});

            const mensaje = userId ? 'Usuario actualizado con éxito:' : 'Usuario creado con éxito:';

            toast.success(mensaje, {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

            console.log(mensaje, response.data);
           
        // Redirigir o mostrar mensaje de éxito...
        } catch (error) {
        // Manejar errores...
        console.error('Error al crear el usuario:', error);
        }
		setIsSubmitting(false); 
    };

  return (
    <LayoutMain title="Usuario">
        {/* begin::Toolbar */}
							<div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
								{/* begin::Toolbar container */}
								<div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
									{/* begin::Toolbar wrapper */}
									<div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
										{/* begin::Page title */}
										<div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
											{/* begin::Title */}
											<h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">{userId ? 'Editar Usuario' : 'Agregar Usuario'}</h1>
											{/* end::Title */}
											{/* begin::Breadcrumb */}
											<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
												{/* begin::Item */}
												<li  className="breadcrumb-item text-muted">Pymes</li>
												{/* end::Item */}
												{/* begin::Item */}
												<li  className="breadcrumb-item">
													<span  className="bullet bg-gray-500 w-5px h-2px"></span>
												</li>
												{/* end::Item */}
												{/* begin::Item */}
                                                <li  className="breadcrumb-item text-muted">
                                                    <Link to="/pymes-usuarios">Usuarios</Link>
                                                </li>
                                                {/* end::Item */}
                                                {/* begin::Item */}
												<li  className="breadcrumb-item">
													<span  className="bullet bg-gray-500 w-5px h-2px"></span>
												</li>
												{/* end::Item */}
                                                {/* begin::Item */}
                                                <li  className="breadcrumb-item text-muted">Usuario</li>
                                                {/* end::Item */}
											</ul>
											{/* end::Breadcrumb */}
										</div>
										{/* end::Page title */}
										{/* begin::Actions */}
										<div className="d-flex align-items-center gap-2 gap-lg-3">
											<Link to="/pymes-usuarios" className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" >
											<i className="ki-outline ki-black-left fs-4"></i>Regresar</Link>
										</div>
										{/* end::Actions */}
									</div>
									{/* end::Toolbar wrapper */}
								</div>
								{/* end::Toolbar container */}
							</div>
							{/* end::Toolbar */}
							{/* begin::Content */}
							<div id="kt_app_content" className="app-content flex-column-fluid">
								{/* begin::Content container */}
								<div id="kt_app_content_container" className="app-container container-fluid">
									{/* begin::Contacts App- Add New Contact */}
									<div className="row g-7">
                                        {/* begin::Content */}
										<div className="col-xl-8 col-xxl-6">
											{/* begin::Contacts */}
											<div className="card card-flush h-lg-100" id="kt_contacts_main">
												{/* begin::Card body */}
												<div className="card-body pt-5">
													{/* begin::Form */}
													<form onSubmit={handleSubmit} className="form" >
														{/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span>RUC / DNI / CE / Código</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                name="ruc_dni"
                                                                value={rucDni}
                                                                onChange={(e) => setRucDni(e.target.value)}
																autoComplete="new-password"
                                                            />
															{/* end::Input */}
														</div>
														{/* end::Input group */}
                                                        {/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span>Razón Social / Empresa</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                name="empresa"
                                                                value={empresa}
                                                                onChange={(e) => setEmpresa(e.target.value)}
																autoComplete="new-password"
                                                            />
															{/* end::Input */}
														</div>
														{/* end::Input group */}
														{/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span className="required">Nombre Completo</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                name="nombre"
                                                                value={nombre}
                                                                onChange={(e) => setNombre(e.target.value)}
																autoComplete="new-password"
                                                            />
															{/* end::Input */}
														</div>
														{/* end::Input group */}
														{/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span className="required">E-mail</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<input
                                                                type="email"
                                                                className="form-control form-control-solid"
                                                                name="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
																autoComplete="new-password"
                                                            />
															{/* end::Input */}
														</div>
														{/* end::Input group */}
                                                        {/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span className="required">Teléfono</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                name="telefono"
                                                                value={telefono}
                                                                onChange={(e) => setTelefono(e.target.value)}
																autoComplete="new-password"
                                                            />
															{/* end::Input */}
														</div>
														{/* end::Input group */}
														{/* begin::Row */}
														<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
															{/* begin::Col */}
															<div className="col">
																{/* begin::Input group */}
																<div className="fv-row mb-7">
																	{/* begin::Label */}
																	<label className="fs-6 fw-semibold form-label mt-3">
																		<span className="required">Inicio Membresía</span>
																	</label>
																	{/* end::Label */}
																	{/* begin::Input */}
																	<DatePicker
                                                                        selected={inicioMembresia}
                                                                        onChange={(date) => setInicioMembresia(date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        isClearable
                                                                        placeholderText="Inicio Membresía"
                                                                        className="tableDatetimeInput form-control form-control-solid"
                                                                        />
																	{/* end::Input */}
																</div>
																{/* end::Input group */}
															</div>
															{/* end::Col */}
															{/* begin::Col */}
															<div className="col">
																{/* begin::Input group */}
																<div className="fv-row mb-7">
																	{/* begin::Label */}
																	<label className="fs-6 fw-semibold form-label mt-3">
																		<span className="required">Fin Membresía</span>
																	</label>
																	{/* end::Label */}
																	<div className="w-100">
																		{/* begin::Select2 */}
																		<DatePicker
                                                                            selected={finMembresia}
                                                                            onChange={(date) => setFinMembresia(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            isClearable
                                                                            placeholderText="Fin Membresía"
                                                                            className="tableDatetimeInput form-control form-control-solid"
																			autoComplete="new-password"
                                                                            />
																		{/* end::Select2 */}
																	</div>
																</div>
																{/* end::Input group */}
															</div>
															{/* end::Col */}
														</div>
														{/* end::Row */}
                                                        {/* begin::Row */}
														<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
															{/* begin::Col */}
															<div className="col">
																{/* begin::Input group */}
																<div className="fv-row mb-7">
																	{/* begin::Label */}
																	<label className="fs-6 fw-semibold form-label mt-3">
																		<span className="required">Contraseña</span>
																	</label>
																	{/* end::Label */}
																	{/* begin::Input */}
																	<input
                                                                        type="password"
                                                                        className="form-control form-control-solid"
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
																		autoComplete="new-password"
                                                                    />
																	{/* end::Input */}
																</div>
																{/* end::Input group */}
															</div>
															{/* end::Col */}
															{/* begin::Col */}
															<div className="col">
																{/* begin::Input group */}
																<div className="fv-row mb-7">
																	{/* begin::Label */}
																	<label className="fs-6 fw-semibold form-label mt-3">
																		<span className="required">Confirmar contraseña</span>
																	</label>
																	{/* end::Label */}
																	{/* begin::Input */}
																	<input
                                                                        type="password"
                                                                        className="form-control form-control-solid"
                                                                        name="password_confirm"
                                                                        value={passwordConfirm}
                                                                        onChange={(e) => setPasswordConfirm(e.target.value)}
																		autoComplete="new-password"
                                                                    />
																	{/* end::Input */}
																</div>
																{/* end::Input group */}
															</div>
															{/* end::Col */}
														</div>
														{/* end::Row */}
                                                        {/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span className="required">País</span>
															</label>
															{/* end::Label */}
															{/* begin::Select2 */}
                                                            <select className="form-select form-select-solid" value={selectedPais} onChange={(e) => setSelectedPais(e.target.value)} >
                                                                {PaisesLista.map((pais, i) => (
                                                                    <option value={pais.value}>{pais.label}</option>
                                                                ))}
                                                            </select>
															{/* end::Select2 */}
														</div>
														{/* end::Input group */}
                                                        {/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span className="required">Grupo</span>
															</label>
															{/* end::Label */}
															{/* begin::Select2 */}
                                                            <select className="form-control form-control-solid" value={selectedUserGroup} onChange={(e) => setSelectedUserGroup(e.target.value)} required="required">
                                                                <option value="">Escoger grupo</option>
                                                                {gruposUsuarios.map((grupo) => (
                                                                    <option key={grupo.usergroup_id} value={grupo.usergroup_id}>{grupo.usergroup_name}</option>
                                                                ))}
                                                            </select>
															{/* end::Select2 */}
														</div>
														{/* end::Input group */}
                                                       
                                                      

														{/* begin::Input group */}
														<div className="fv-row mb-7">
															{/* begin::Label */}
															<label className="fs-6 fw-semibold form-label mt-3">
																<span>Notas</span>
															</label>
															{/* end::Label */}
															{/* begin::Input */}
															<textarea
                                                                className="form-control form-control-solid"
                                                                name="notas"
                                                                value={notas}
                                                                onChange={(e) => setNotas(e.target.value)}
																autoComplete="new-password"
                                                            ></textarea>
															{/* end::Input */}
														</div>
														{/* end::Input group */}
														{/* begin::Separator */}
														<div className="separator mb-6"></div>
														{/* end::Separator */}
														{/* begin::Action buttons */}
														<div className="d-flex justify-content-end">
															{/* begin::Button */}
															<button type="reset" data-kt-contacts-type="cancel" className="btn btn-light me-3">Cancelar</button>
															{/* end::Button */}
															{/* begin::Button */}
															<button type="submit" className="btn btn-primary" disabled={isSubmitting}>
																{isSubmitting ? (
																	<>
																		Por favor espera...
																		<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
																	</>
																) : (
																	"Guardar"
																)}
															</button>
															{/* end::Button */}
														</div>
														{/* end::Action buttons */}
													</form>
													{/* end::Form */}
												</div>
												{/* end::Card body */}
											</div>
											{/* end::Contacts */}
										</div>
										{/* end::Content */}
									</div>
									{/* end::Contacts App- Add New Contact */}
								</div>
								{/* end::Content container */}
							</div>
							{/* end::Content */}
        <ToastContainer />
    </LayoutMain>  
  );
}

export default UsuarioPage;
