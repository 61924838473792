import React from "react";

function Footer(){

    return(
        <div id="kt_app_footer" className="app-footer">
			{/* begin::Footer container*/} 
			<div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
				{/* begin::Copyright*/} 
				<div className="text-gray-900 order-2 order-md-1">
					<span className="text-muted fw-semibold me-1">2024&copy;</span>
					<a href="https://tildecreativa.com" target="_blank" className="text-gray-800 text-hover-primary">Desarrollado por Tilde Creativa</a>
				</div>
				{/* end::Copyright*/} 
				{/* begin::Menu*/} 
				<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
					<li className="menu-item">
						<a href="https://app.ptpbizintelligence.com" target="_blank" className="menu-link px-2">Top Online</a>
					</li>
					<li className="menu-item">
						<a href="https://minas.ptpbizintelligence.com" target="_blank" className="menu-link px-2">Minas</a>
					</li>
					<li className="menu-item">
						<a href="https://pymes.ptpbizintelligence.com" target="_blank" className="menu-link px-2">Pymes</a>
					</li>
					<li className="menu-item">
						<a href="https://infra.ptpbizintelligence.com" target="_blank" className="menu-link px-2">Pymes</a>
					</li>
				</ul>
				{/* end::Menu*/} 
			</div>
			{/* end::Footer container*/} 
		</div>
    )


}

export default Footer;