import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';

// Función para manejar el formateo de la fecha
  const formatDate = (date) => {
	return format(date, 'dd/MM/yyyy');
  };

function PerfilesPage() {

	const token = localStorage.getItem('jwtToken');
    const [perfiles, setPerfiles] = useState([]);
	
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(100);
    const [totalPerfiles, setTotalPerfiles] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedPerfilId, setSelectedPerfilId] = useState(null);

	function FormattedDate({ dateString }) {
		const date = new Date(dateString);
		const formattedDate = format(date, 'dd/MM/yyyy'); // Formato día/mes/año
	  
		return <span>{formattedDate}</span>;
	  }

	  

	
    useEffect(() => {
		// Llamar a fetchPerfiles solo si search tiene más de 2 caracteres o al cambiar otros filtros
		fetchPerfiles();
	}, [paginaActual, itemsPorPagina]);

	const fetchPerfiles = async () => {
		let queryParams = {
			page: paginaActual,
			'per-page': itemsPorPagina,
		};
	
		const queryString = new URLSearchParams(queryParams).toString();
		const url = `https://api.ptpbizintelligence.com/api/web/admin-minas/minas-perfiles-list?${queryString}`;
	
		try {
			const response = await axios.get(url,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
			});
			setPerfiles(response.data.perfiles);
			setTotalPerfiles(response.data.total);
			setTotalPaginas(response.data.totalPages);
		} catch (error) {
			console.error('Hubo un error al obtener los datos de los perfiles:', error);
		}
	};
	

    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
		// Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}
		
		/*
		return (
			<div>
				<a className="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a className="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
		*/
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalPerfiles ? totalPerfiles : fin; // Asegurarse de no superar el total de perfiles
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalPerfiles) {
			fin = totalPerfiles;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{totalPerfiles}</b> perfiles
			</div>
			)
	};

	const confirmDelete = (perfilId) => {
		setSelectedPerfilId(perfilId);
		setShowDeleteConfirm(true);
		console.log('confirmDelete:',perfilId);
	  };
	  
	const deletePerfil = async () => {
		setShowDeleteConfirm(false);
		// Aquí iría el código para llamar a la API y eliminar el perfil
		try {
		await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/minas/minasDeletePerfil/${selectedPerfilId}`,{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		});
		toast.success('Perfil eliminado con éxito');
		fetchPerfiles();
		// Luego de eliminar, podrías recargar la lista de perfiles o quitar el perfil de la lista en el estado
		} catch (error) {
		toast.error('Error al eliminar el perfil');
		}
	};

	
	  
	return(
        <LayoutMain title="Perfiles">
			<ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deletePerfil()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este perfil.
			</SweetAlert>
			)}
            {/* begin::Toolbar */}
            	<div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
					{/* begin::Toolbar container */}
					<div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
						{/* begin::Toolbar wrapper */}
						<div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
							{/* begin::Page title */}
							<div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
								{/* begin::Title */}
								<h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Perfiles</h1>
								{/* end::Title */}
								{/* begin::Breadcrumb */}
								<ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Minas</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Perfiles</li>
									{/* end::Item */}
								</ul>
								{/* end::Breadcrumb */}
							</div>
							{/* end::Page title */}
							{/* begin::Actions */}
							<div  className="d-flex align-items-center gap-2 gap-lg-3">
								{/*
								<a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
								<a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_perfiles">
								<i  className="ki-outline ki-eye fs-4"></i>Preview</a>
								*/}
								<Link to="/minas-perfil"  className="btn btn-sm btn-flex btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">
								<i  className="ki-outline ki-note-2 fs-4"></i>Agregar Perfil</Link>
							</div>
							{/* end::Actions */}
						</div>
						{/* end::Toolbar wrapper */}
					</div>
					{/* end::Toolbar container */}
				</div>
				{/* end::Toolbar */}
				{/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										{/* 
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										*/}
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                            <th  className="min-w-25px">Id</th>
                                            <th  className="min-w-125px">Nombre</th>
                                            <th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{perfiles.map((perfil, index) => (
											<tr key={index}>
                                                <td>{perfil.id}</td>
                                                <td>{perfil.nombre}</td>
												<td className="text-center">
													<Link to={`/minas-perfil/${perfil.id}`}  className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar"><i className="ki-outline ki-notepad-edit"></i></Link>
                                                    <Link to={`/minas-perfil-mapa/${perfil.id}`}  className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar"><i className="ki-outline ki-geolocation"></i></Link>
                                                    <Link to={`/minas-perfil-galeria/${perfil.id}`}  className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar"><i className="ki-outline ki-picture"></i></Link>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(perfil.id)}><i className="ki-outline ki-trash"></i></button>
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>
        </LayoutMain>                    
    )
}

export default PerfilesPage;