import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form, Table } from 'react-bootstrap';

function KioskoCategoriasPage(){
    const token = localStorage.getItem('jwtToken');
    const [kioskoCategorias, setKioskoCategorias] = useState([]);
	const [search, setSearch] = useState("");
    const [availableYears, setAvailableYears] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedCategoriaId, setSelectedCategoriaId] = useState(null);
    const [selectedDeletePDF, setSelectedDeletePDF] = useState(null);
    const [selectedDeleteYearPDF, setSelectedDeleteYearPDF] = useState(null);
    const [showDeletePdfConfirm, setShowDeletePdfConfirm] = useState(false);

    const [showModal, setShowModal] = useState(false); // Controlar la visibilidad del modal
    const [isEditMode, setIsEditMode] = useState(false); // Diferenciar entre agregar y editar
    const [editRecordId, setEditRecordId] = useState(null);

    const initialState = {
         kioskocategoria_nombre: ''
        // Asumiendo que inicialmente no hay años disponibles, se añadirán dinámicamente más adelante
      };
    const [formData, setFormData] = useState(initialState);

    const [publicaciones,setPublicaciones] = useState([]);
    const [totalRegistrosPublicaciones, setTotalRegistrosPublicaciones] = useState(0);
	  	 
    useEffect(() => {
		// Llamar a fetchUsuarios solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if(search.length > 2 || search.length === 0) {
            fetchCategorias();
        }
	}, [search,  paginaActual, itemsPorPagina]);

	const fetchCategorias = async () => {
        let queryParams = {
            page: paginaActual,
            'per-page': itemsPorPagina,
        };
    
        if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `https://api.ptpbizintelligence.com/api/web/admin/kioskoPublicacionesCategoriasList?${queryString}`;
    
        try {
            const response = await axios.get(url,{
                headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
            });
            setKioskoCategorias(response.data.categorias); // Asegúrate de que este campo coincida con la respuesta de tu API
            setTotalRegistros(response.data.total);
            setTotalPaginas(response.data.totalPaginas); // Asegúrate de que este campo coincida con la respuesta de tu API
            setAvailableYears(response.data.availableYears); // Asume que este campo viene en la respuesta de la API
            console.log(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los datos de las categorías:', error);
        }
    };
	
    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
        // Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}

	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalRegistros ? totalRegistros : fin; // Asegurarse de no superar el total de usuarios
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalRegistros) {
			fin = totalRegistros;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalRegistros > fin && <>de <b>{totalRegistros}</b></>} roles
			</div>
			)
	};

    const deleteRegistro = async () => {
        setShowDeleteConfirm(false); // Cierra el modal de confirmación
    
        try {
            // Asegúrate de cambiar la URL y el endpoint según tu configuración de API
            await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/deleteKioskoPublicacionesCategoria/${selectedCategoriaId}`,{
                headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
            });
            
            toast.success('Categoría eliminada con éxito');
            fetchCategorias(); // Asumiendo que tienes una función para recargar los estados financieros
    
        } catch (error) {
            toast.error('Error al eliminar la Categoría');
        }
    };

    const confirmDelete = (kioskocategoria_id) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setSelectedCategoriaId(kioskocategoria_id); // Asegúrate de tener este estado definido para usarlo luego
        setShowDeleteConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirm delete for categoría ID:', kioskocategoria_id);
    };

    const openModalForCreate = () => {
        setIsEditMode(false);
        // Inicializa un objeto para los campos de archivos PDF basado en availableYears
        /*
        const archivosPDFIniciales = availableYears.reduce((acc, year) => {
            acc[`estadosfin_eeff${year}`] = ''; // Inicializa cada campo como vacío o null
            return acc;
        }, {});
    */
        setFormData({
            kioskocategoria_nombre: '',
        });
    
    
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const openModalForEdit = async (kioskoCategoriaId) => {
        setIsEditMode(true);
        setSelectedCategoriaId(kioskoCategoriaId);
        console.log('Categoría Id:',kioskoCategoriaId);
        // Aquí, asumimos que tienes una función para obtener los detalles de un estado financiero por su ID
        try {
          const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getKioskoPublicacionesCategoria/${kioskoCategoriaId}`,{
            headers: {
                Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
            }
          });
          const data = response.data;
            console.log('categoría:',response.data);
          // Suponiendo que `data` contiene todos los campos necesarios
          // Actualiza el estado del formulario con estos datos
         

          setFormData({
            kioskocategoria_nombre: data.kioskocategoria_nombre,
            
            // Asegúrate de manejar los campos de archivos PDF de manera adecuada
            // Es posible que necesites una lógica especial si planeas permitir a los usuarios ver o reemplazar los archivos PDF existentes
          });
      
          // Si necesitas manejar archivos PDF existentes, considera guardar en el estado la información necesaria para mostrarlos o reemplazarlos
        } catch (error) {
          console.error('Error al recuperar los detalles de la categoría', error);
          toast.error('Error al cargar los datos para edición');
        }
      
        setShowModal(true);// Asumiendo que esta función abre el modal
    };

    const handleFileChange = (e, year) => {
        setFormData(prev => ({
          ...prev,
          [`estadosfin_eeff${year}`]: e.target.files[0], // Solo se toma el primer archivo seleccionado
        }));
      };

    const handleSubmit = async () => {
        // Crea un objeto FormData y agrega los campos del formulario
        const formDataForApi = new FormData();
        Object.keys(formData).forEach(key => {
            formDataForApi.append(key, formData[key]);
        });
        
    
        // Determina la URL y el método HTTP según si estamos agregando o editando
        const url = isEditMode
            ? `https://api.ptpbizintelligence.com/api/web/admin/editarKioskoPublicacionesCategoria/${selectedCategoriaId}`
            : 'https://api.ptpbizintelligence.com/api/web/admin/crearKioskoPublicacionesCategoria';
        const method = isEditMode ? 'POST' : 'POST'; // Asume que para editar se usa el método PATCH
    
        try {
            // Solicitud HTTP para crear o editar el estado financiero
            const response = await axios({
                method,
                url,
                data: formDataForApi,
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`  
                },
            });
    
            // Si la operación es exitosa, muestra un mensaje y actualiza la lista
            console.log(response.data);
            if(response.data.success == true)
            {
                toast.success(`Estado Financiero ${isEditMode ? 'editado' : 'creado'} con éxito`);
                fetchCategorias(); // Refresca la lista
                closeModal(); // Cierra el modal
            }
            
        } catch (error) {
            console.error(`Error al ${isEditMode ? 'editar' : 'crear'} la Categoría`, error);
            toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} la Categoría`);
        }
    };
    


    return(
        <LayoutMain title="Kiosko">
			<ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteRegistro()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar esta categoría
			</SweetAlert>
			)}
            {/* begin::Toolbar */}
            	<div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
					{/* begin::Toolbar container */}
					<div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
						{/* begin::Toolbar wrapper */}
						<div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
							{/* begin::Page title */}
							<div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
								{/* begin::Title */}
								<h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Kiosko - Categorías</h1>
								{/* end::Title */}
								{/* begin::Breadcrumb */}
								<ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Top Online</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Kiosko</li>
									{/* end::Item */}
                                    {/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Categorías</li>
									{/* end::Item */}
								</ul>
								{/* end::Breadcrumb */}
							</div>
							{/* end::Page title */}
							{/* begin::Actions */}
							<div  className="d-flex align-items-center gap-2 gap-lg-3">
								<a  className="btn btn-sm btn-flex btn-primary" onClick={()=>openModalForCreate()} ><i  className="ki-outline ki-category fs-4"></i>Agregar Categoría</a> 
							</div>
							{/* end::Actions */}
						</div>
						{/* end::Toolbar wrapper */}
					</div>
					{/* end::Toolbar container */}
				</div>
				{/* end::Toolbar */}
				{/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                            <th  className="min-w-125px">Nombre</th>
                                            <th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{kioskoCategorias.map((kioskoCategoria, index) => (
											<tr key={index}>
                                                <td>{kioskoCategoria.kioskocategoria_nombre}</td>
												
												<td className="text-center">
                                                    
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar" onClick={()=>openModalForEdit(kioskoCategoria.kioskocategoria_id)}><i class="ki-outline ki-notepad-edit"></i></button>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(kioskoCategoria.kioskocategoria_id)}><i class="ki-outline ki-trash"></i></button>
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Editar Categoría' : 'Agregar Categoría'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formNombre">
                                <Form.Label>Nombre de la Categoría</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nombre"
                                    name="kioskocategoria_nombre"
                                    value={formData.kioskocategoria_nombre || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            

                        </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                            </Button>
                        </Modal.Footer>
                </Modal>

              



        </LayoutMain>                    
    )

}

export default KioskoCategoriasPage;