import React from "react";
import LayoutMain from '../../components/LayoutMain';

function MenusPage(){


    return(
        <LayoutMain title="Menus">
        Menus
        </LayoutMain>
    )

}

export default MenusPage; 