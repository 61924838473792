import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form } from 'react-bootstrap';

function EstadosFinancierosPdf(){
    const token = localStorage.getItem('jwtToken');
    const [estadosFinancieros, setEstadosFinancieros] = useState([]);
	const [search, setSearch] = useState("");
    const [availableYears, setAvailableYears] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedEstFinId, setSelectedEstFinId] = useState(null);
    const [selectedDeletePDF, setSelectedDeletePDF] = useState(null);
    const [selectedDeleteYearPDF, setSelectedDeleteYearPDF] = useState(null);
    const [showDeletePdfConfirm, setShowDeletePdfConfirm] = useState(false);

    const [showModal, setShowModal] = useState(false); // Controlar la visibilidad del modal
    const [isEditMode, setIsEditMode] = useState(false); // Diferenciar entre agregar y editar
    const [editRecordId, setEditRecordId] = useState(null);
    const initialState = {
        estadosfin_ruc: '',
        estadosfin_razonsocial: '',
        archivosPDF: {}
        // Asumiendo que inicialmente no hay años disponibles, se añadirán dinámicamente más adelante
      };
    const [formData, setFormData] = useState(initialState);
	  	 
    useEffect(() => {
		// Llamar a fetchEstadosFinancieros solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if(search.length > 2 || search.length === 0) {
            fetchEstadosFinancieros();
        }
	}, [search,  paginaActual, itemsPorPagina]);

	const fetchEstadosFinancieros = async () => {
        let queryParams = {
            page: paginaActual,
            'per-page': itemsPorPagina,
        };
    
        if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `https://api.ptpbizintelligence.com/api/web/admin/estadosFinancierosList?${queryString}`;
    
        try {
            const response = await axios.get(url,{
                headers: {
					Authorization: `Bearer ${token}`  // Añadir el token JWT en el encabezado Authorization
				},
            });
            setEstadosFinancieros(response.data.estadosFinancieros); // Asegúrate de que este campo coincida con la respuesta de tu API
            setTotalRegistros(response.data.total);
            setTotalPaginas(response.data.totalPaginas); // Asegúrate de que este campo coincida con la respuesta de tu API
            setAvailableYears(response.data.availableYears); // Asume que este campo viene en la respuesta de la API
            console.log(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los datos de los estados financieros:', error);
        }
    };
	
    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
        // Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}

	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalRegistros ? totalRegistros : fin; // Asegurarse de no superar el total de estados financieros
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalRegistros) {
			fin = totalRegistros;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalRegistros > fin && <>de <b>{totalRegistros}</b></>} estados financieros
			</div>
			)
	};

	const confirmDelete = (estadofin_id) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setSelectedEstFinId(estadofin_id); // Asegúrate de tener este estado definido para usarlo luego
        setShowDeleteConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirm delete for estado financiero ID:', estadofin_id);
    };
	  
	

    const openModalForCreate = () => {
        setIsEditMode(false);
        // Inicializa un objeto para los campos de archivos PDF basado en availableYears
        const archivosPDFIniciales = availableYears.reduce((acc, year) => {
            acc[`estadosfin_eeff${year}`] = ''; // Inicializa cada campo como vacío o null
            return acc;
        }, {});
    
        setFormData({
            estadosfin_ruc: '',
            estadosfin_razonsocial: '',
            archivosPDF: archivosPDFIniciales, // Asegúrate de que esta estructura coincide con cómo gestionas los archivos PDF en el formulario
        });
    
        setShowModal(true);
    };
      
    const openModalForEdit = async (estadosFinId) => {
        setIsEditMode(true);
        setSelectedEstFinId(estadosFinId);
        console.log('estadosFinId:',estadosFinId);
        // Aquí, asumimos que tienes una función para obtener los detalles de un estado financiero por su ID
        try {
          const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getEstadoFinanciero/${estadosFinId}`,{
            headers: {
                Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
            },
          });
          const data = response.data;
            console.log('estado financiero:',response.data);
          // Suponiendo que `data` contiene todos los campos necesarios
          // Actualiza el estado del formulario con estos datos
          const archivosPDF = availableYears.reduce((acc, year) => {
            const key = `estadosfin_eeff${year}`; // Asume que tus campos en `data` siguen este patrón
            acc[key] = data[key] || null;
            return acc;
          }, {});

          console.log('archivosPDF',archivosPDF);

          setFormData({
            estadosfin_ruc: data.estadosfin_ruc,
            estadosfin_razonsocial: data.estadosfin_razonsocial,
            archivosPDF,
            // Asegúrate de manejar los campos de archivos PDF de manera adecuada
            // Es posible que necesites una lógica especial si planeas permitir a los estados financieros ver o reemplazar los archivos PDF existentes
          });
      
          // Si necesitas manejar archivos PDF existentes, considera guardar en el estado la información necesaria para mostrarlos o reemplazarlos
        } catch (error) {
          console.error('Error al recuperar los detalles del estado financiero', error);
          toast.error('Error al cargar los datos para edición');
        }
      
        setShowModal(true);// Asumiendo que esta función abre el modal
    };
      
    const closeModal = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e, year) => {
        setFormData(prev => ({
          ...prev,
          [`estadosfin_eeff${year}`]: e.target.files[0], // Solo se toma el primer archivo seleccionado
        }));
      };

    const handleSubmit = async () => {
        // Crea un objeto FormData y agrega los campos del formulario
        const formDataForApi = new FormData();
        Object.keys(formData).forEach(key => {
            if (key.startsWith('estadosfin_eeff')) {
                // Asume que `formData` contiene directamente los archivos PDF bajo claves como `estadosfin_eeff2022`
                console.log(`${key}: `, formData[key], 'Type: ', typeof formData[key], 'Instance of File: ', formData[key] instanceof File);
                formDataForApi.append(key, formData[key]);
                
            } else {
                formDataForApi.append(key, formData[key]);
            }
        });
        
    
        // Determina la URL y el método HTTP según si estamos agregando o editando
        const url = isEditMode
            ? `https://api.ptpbizintelligence.com/api/web/admin/editarEstadoFinanciero/${selectedEstFinId}`
            : 'https://api.ptpbizintelligence.com/api/web/admin/crearEstadoFinanciero';
        const method = isEditMode ? 'POST' : 'POST'; // Asume que para editar se usa el método PATCH
    
        try {
            // Solicitud HTTP para crear o editar el estado financiero
            const response = await axios({
                method,
                url,
                data: formDataForApi,
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}` 
                },
            });
    
            // Si la operación es exitosa, muestra un mensaje y actualiza la lista
            console.log(response.data);
            if(response.data.success == true)
            {
                toast.success(`Estado Financiero ${isEditMode ? 'editado' : 'creado'} con éxito`);
                fetchEstadosFinancieros(); // Refresca la lista
                closeModal(); // Cierra el modal
            }
            
        } catch (error) {
            console.error(`Error al ${isEditMode ? 'editar' : 'crear'} el Estado Financiero`, error);
            toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} el Estado Financiero`);
        }
    };
    
      
      const handleDeletePdf = async (year) => {
        const key = `estadosfin_eeff${year}`;
        try {
          // Suponiendo que tienes un endpoint que recibe el ID del estado financiero y el año del PDF a eliminar
          await axios.post(`https://api.ptpbizintelligence.com/api/web/admin/deleteEstadoFinancieroSinglePdf`, {
            estadosfin_id: formData.estadosfin_id,
            year
          },{headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
            });
          // Eliminar el archivo de S3 aquí, si tienes acceso a la API de S3 directamente desde el cliente
          // Esto podría requerir una llamada separada a una función lambda o a tu backend que luego interactúe con S3
      
          // Actualizar el estado para reflejar la eliminación
          setFormData(prev => ({
            ...prev,
            archivosPDF: {
              ...prev.archivosPDF,
              [key]: null // Eliminar el valor del archivo PDF del estado
            }
          }));
          toast.success('Archivo PDF eliminado correctamente');
        } catch (error) {
          console.error('Error al eliminar el archivo PDF', error);
          toast.error('Error al eliminar el archivo PDF');
        }
      };
      
      
    const deleteRegistro = async () => {
        setShowDeleteConfirm(false); // Cierra el modal de confirmación
    
        try {
            // Asegúrate de cambiar la URL y el endpoint según tu configuración de API
            await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/deleteEstadoFinanciero/${selectedEstFinId}`,{
                headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
            });
            
            toast.success('Estado financiero eliminado con éxito');
            fetchEstadosFinancieros(); // Asumiendo que tienes una función para recargar los estados financieros
    
        } catch (error) {
            toast.error('Error al eliminar el estado financiero');
        }
    };

    const handleConfirmDeletePdf = (year,archivoPDF) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setSelectedDeletePDF(archivoPDF); // Asegúrate de tener este estado definido para usarlo luego
        setSelectedDeleteYearPDF(year);
        setShowDeletePdfConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirmar eliminar PDF:', archivoPDF);
    };

    const deletePdf = async () => {
        setShowDeletePdfConfirm(false); // Cierra el modal de confirmación
    
        try {
            const data = {
                estadofin_id: selectedEstFinId,
                year: selectedDeleteYearPDF,
                archivoPDF: selectedDeletePDF,
            };
    
            // Realiza la solicitud al endpoint adecuado con los datos necesarios
            await axios.post(`https://api.ptpbizintelligence.com/api/web/admin/deletePdfEstadoFinanciero`, data,{
                headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
            });
            
            toast.success('PDF eliminado con éxito');
            fetchEstadosFinancieros(); // Recarga los estados financieros
            setShowModal(false);
        } catch (error) {
            console.error('Error al eliminar el PDF', error);
            toast.error('Error al eliminar el PDF');
        }
    };

    return(
        <LayoutMain title="Estados Financieros PDF">
            <ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteRegistro()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este estado financiero.
			</SweetAlert>
			)}
            {showDeletePdfConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deletePdf()}
				onCancel={() => setShowDeletePdfConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este archivo.
			</SweetAlert>
			)}
                <div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
                    {/* begin::Toolbar container */}
                    <div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
                        {/* begin::Toolbar wrapper */}
                        <div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                            {/* begin::Page title */}
                            <div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                                {/* begin::Title */}
                                <h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Estados Financieros PDF</h1>
                                {/* end::Title */}
                                {/* begin::Breadcrumb */}
                                <ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item text-muted">Top Online</li>
                                    {/* end::Item */}
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item">
                                        <span  className="bullet bg-gray-500 w-5px h-2px"></span>
                                    </li>
                                    {/* end::Item */}
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item text-muted">Estados Financieros PDF</li>
                                    {/* end::Item */}
                                </ul>
                                {/* end::Breadcrumb */}
                            </div>
                            {/* end::Page title */}
                            {/* begin::Actions */}
                            <div  className="d-flex align-items-center gap-2 gap-lg-3">
                                {/*
                                <a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
                                <a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                <i  className="ki-outline ki-eye fs-4"></i>Preview</a>
                                */}
                                <button onClick={()=>openModalForCreate()} className="btn btn-sm btn-flex btn-primary">
                                <i  className="ki-outline ki-document fs-4"></i>Agregar</button>
                            </div>
                            {/* end::Actions */}
                        </div>
                        {/* end::Toolbar wrapper */}
                    </div>
                    {/* end::Toolbar container */}
                </div>
                {/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							{/* begin::Card header */}
							<div  className="card-header border-0 pt-6">
								{/* begin::Card title */}
								<div  className="card-title">
									{/* begin::Search */}
									<div  className="d-flex align-items-center position-relative my-1">
										<i  className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
										<input type="text" data-kt-customer-table-filter="search"  className="form-control form-control-solid w-350px ps-12 tablaBuscador" placeholder="Buscar RUC, Razón Social"  value={search} onChange={(e) => setSearch(e.target.value)} />
									</div>
									{/* end::Search */}
									
								</div>
								{/* begin::Card title */}
								
							</div>
							{/* end::Card header */}
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
											<th  className="min-w-25px">ID</th>
											<th  className="min-w-125px">RUC</th>
											<th  className="min-w-125px">Razón Social</th>
											{availableYears.map((year) => (
                                                <th key={year} className="min-w-125px">EEFF {year}</th> 
                                            ))}
											<th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{estadosFinancieros.map((estfin, index) => (
											<tr key={index}>
												<td>{estfin.estadosfin_id}</td>
												<td>{estfin.estadosfin_ruc}</td>
												<td>{estfin.estadosfin_razonsocial}</td>
												{availableYears.map((year) => (
                                                // Asumiendo que cada `estadofin` tiene una propiedad directa por cada año, por ejemplo, eeff2022, eeff2021, etc.
                                                <td key={year}>
                                                    {estfin[`estadosfin_eeff${year}`] ? (
                                                            <a target="_blank" href={`https://ptpbizdocs.s3.amazonaws.com/toponline/estadosfinancieros/${estfin[`estadosfin_eeff${year}`]}`}>
                                                                {`EEFF ${year}`}
                                                            </a>
                                                        ) : (
                                                            // Puedes dejar esto vacío o mostrar un mensaje específico si prefieres
                                                            ''
                                                        )}
                                                    </td>
                                                ))}
												<td>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar" onClick={()=>openModalForEdit(estfin.estadosfin_id)}><i class="ki-outline ki-notepad-edit"></i></button>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(estfin.estadosfin_id)}><i class="ki-outline ki-trash"></i></button>
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Editar Estado Financiero' : 'Agregar Estado Financiero'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formRuc">
                            <Form.Label>RUC</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="RUC"
                                name="estadosfin_ruc"
                                value={formData.estadosfin_ruc || ''}
                                onChange={handleChange}
                            />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formRazonSocial">
                            <Form.Label>Razón Social</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Razón Social"
                                name="estadosfin_razonsocial"
                                value={formData.estadosfin_razonsocial || ''}
                                onChange={handleChange}
                            />
                            </Form.Group>

                            {
                                availableYears.map(year => {
                                    const key = `estadosfin_eeff${year}`;
                                    const archivoPDF = formData.archivosPDF[key];
                                    return (
                                    <Form.Group key={year} className="mb-3" controlId={`formEEFF${year}`}>
                                        <Form.Label>{`EEFF ${year}`}</Form.Label>
                                        {
                                        archivoPDF ? (
                                            <div>
                                            {/* Muestra enlaces si el archivo PDF ya está cargado */}
                                            <a href={`https://ptpbizdocs.s3.amazonaws.com/toponline/estadosfinancieros/${archivoPDF}`} target="_blank">Ver</a> | <a type="button" onClick={() => handleConfirmDeletePdf(year,archivoPDF)}>Eliminar</a>
                                            </div>
                                        ) : (
                                            // Muestra el campo de carga si no hay archivo PDF
                                            <Form.Control
                                            type="file"
                                            name={key}
                                            onChange={(e) => handleFileChange(e, year)}
                                            accept=".pdf"
                                            />
                                        )
                                        }
                                    </Form.Group>
                                    );
                                })
                            }

                        </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                            </Button>
                        </Modal.Footer>
                </Modal>

			
        </LayoutMain>
    )

}

export default EstadosFinancierosPdf;