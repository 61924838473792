import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Menu from './Menu';
import Aside from './Aside';
import Footer from './Footer';

// CSS

import '../assets/plugins/custom/vis-timeline/vis-timeline.bundle.css';
import '../assets/plugins/custom/datatables/datatables.bundle.css';
import '../assets/css/style.bundle.css';
import '../assets/plugins/global/plugins.bundle.css';
import '../assets/css/style.custom.css';
import '../assets/css/style.responsive.custom.css';



function LayoutMain({ children, title }) {
    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                <Helmet>
                    <title>{title ? `${title} - PTP Admin` : 'PTP Admin'}</title>
                    {/* Puedes añadir más elementos al head aquí si es necesario */}
                </Helmet>
                <Header />
                <div className="app-wrapper flex-row-fluid" id="kt_app_wrapper">
                    <Aside />
                    <div className='app-main flex-column flex-row-fluid' id="kt_app_main">
                        <div className='d-flex flex-column flex-column-fluid' >
                            {children}
                        </div>
                        <Footer />
                    </div>
                    
                </div>
                
                
            </div>
        </div>
    );
}

export default LayoutMain;