import React, { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeAsideItem, setActiveAsideItem] = useState(null);

  const handleMenuChange = (menu) => {
    setActiveMenu(menu);
    setActiveSubmenu(null); // Resetea el submenú al cambiar el menú
    setActiveAsideItem(null);
  };

  const handleSubmenuChange = (submenu) => {
    setActiveSubmenu(submenu);
    setActiveAsideItem(null);
  };

  const handleAsideItemChange = (itemName) => {
    setActiveAsideItem(itemName);
  };

  return (
    <MenuContext.Provider value={{ activeMenu, activeSubmenu, handleMenuChange, handleSubmenuChange, handleAsideItemChange }}>
      {children}
    </MenuContext.Provider>
  );
};
