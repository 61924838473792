import React from "react";
import LayoutMain from '../../components/LayoutMain';

function PymesPage(){


    return(
        <LayoutMain title="Podcast">
        Pymes
        </LayoutMain>
    )

}

export default PymesPage;