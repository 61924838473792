import React from "react";
import LayoutMain from '../../components/LayoutMain';

function AnalyticsPage(){


    return(
        <LayoutMain title="Analytics">
        Analytics (T) 
        </LayoutMain>
    )

}

export default AnalyticsPage;