import React, { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';

//CSS
import "../assets/plugins/global/plugins.bundle.css";
import "../assets/css/style.bundle.css";
import "../assets/css/login.custom.css";

//IMAGES
import ptplogo from '../assets/media/logos/nuevo_logo_ptp_web.png';
import ptpadminlogo from '../assets/media/logos/ptp_admin_v2.png';


function LoginPage() {
    
    const navigate = useNavigate();
    const { userData,setUserData } = useContext(UserContext);

    const handleLogin = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        // Actualizando el endpoint para apuntar a tu API
        axios.post('https://api.ptpbizintelligence.com/api/web/auth/adminlogin', { email, password })
            .then(response => {
                const { token, ...userData } = response.data;
                localStorage.setItem('jwtToken', token);
                setUserData(userData); // Actualiza el contexto con los datos del usuario
                console.log('respuesta data:',response.data);
            
                // Redirige al usuario
                if (token) {
                    navigate('/dashboard');
                    console.log('token:',token);
                }
            })
            .catch(error => {
                console.error("Error durante el inicio de sesión: ", error);
                alert('Error durante el inicio de sesión');
            });
    };
    
   return (

    <div className="d-flex flex-column flex-root rootLogin" id="kt_app_root">
        {/*begin::Page bg image*/}
       
        {/*end::Page bg image*/}
        {/*begin::Authentication - Sign-in */}
        <div className="d-flex flex-column flex-column-fluid flex-lg-row">
            {/*begin::Aside*/}
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                {/*begin::Aside*/}
                <div className="d-flex flex-center flex-lg-start flex-column">
                    {/*begin::Logo*/}
                    <a href="index.html" className="mb-7">
                        <img alt="PTP Admin Logo" className='ptpadminLogo' src={ptpadminlogo} />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Title*/}
                    <h2 className="text-white fw-normal m-0 txtBlanco">Un administrador para gestionarlo todo</h2>
                    {/*end::Title*/}
                </div>
                {/*begin::Aside*/}
            </div>
            {/*begin::Aside*/}
            {/*begin::Body*/}
            <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20 loginCaja">
                {/*begin::Card*/}
                <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                        {/*begin::Form*/}
                        <form className="form w-100"  id="kt_sign_in_form"  action="#" onSubmit={handleLogin}>
                            {/*begin::Heading*/}
                            <div className="text-center mb-11">
                                {/*begin::Title*/}
                                <img src={ptplogo} className="ptpLogo" alt="Peru Top Publications Logo" />
                                <h1 className="text-gray-900 fw-bolder mb-3">Ingresar</h1>
                                {/*end::Title*/}
                                {/*begin::Subtitle
                                <div className="text-gray-500 fw-semibold fs-6">Ingresa con tus credenciales</div>
                                end::Subtitle=*/}
                            </div>
                            {/*begin::Heading*/}
                           
                           
                            {/*begin::Input group=*/}
                            <div className="fv-row mb-8">
                                {/*begin::Email*/}
                                <input type="text" placeholder="Correo electrónico" name="email" autoComplete="off" className="form-control bg-transparent" />
                                {/*end::Email*/}
                            </div>
                            {/*end::Input group=*/}
                            <div className="fv-row mb-3">
                                {/*begin::Password*/}
                                <input type="password" placeholder="Contraseña" name="password" autoComplete="off" className="form-control bg-transparent" />
                                {/*end::Password*/}
                            </div>
                            {/*end::Input group=*/}
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div></div>
                                {/*begin::Link*/}
                                <a href="authentication/layouts/creative/reset-password.html" className="link-primary">¿Olvidaste tu contraseña?</a>
                                {/*end::Link*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Submit button*/}
                            <div className="d-grid mb-10">
                                <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                                    {/*begin::Indicator label*/}
                                    <span className="indicator-label">Ingresar</span>
                                    {/*end::Indicator label*/}
                                    {/*begin::Indicator progress*/}
                                    <span className="indicator-progress">Please wait... 
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    {/*end::Indicator progress*/}
                                </button>
                            </div>
                            {/*end::Submit button*/}
                           
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Wrapper*/}
                   
                </div>
                {/*end::Card*/}
            </div>
            {/*end::Body*/}
        </div>
        {/*end::Authentication - Sign-in*/}
    </div>

   );
}

export default LoginPage;
