import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomEditor from '../../components/CustomEditor'; 

// Asumiendo que LayoutMain es un componente existente en tu proyecto
import LayoutMain from '../../components/LayoutMain';

const MenuSelector = ({ value, onChange }) => {
  const menuOptions = [
    { value: 'pages', label: 'Pages' },
    { value: 'panorama', label: 'Panorama' },
    { value: 'introduccion', label: 'Introducción' },
    { value: 'entrevistas', label: 'Entrevistas' },
  ];

  return (
    <select
      className="form-select"
      value={value}
      onChange={(e) => onChange('menu', e.target.value)}
    >
      {menuOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

function MinasContenido() {
    const token = localStorage.getItem('jwtToken');
    const { contenidoId } = useParams();
    const [contenido, setContenido] = useState({
        menu: '',
        titulo: '',
        texto: '',
        title: '',
        text: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (contenidoId) {
            axios.get(`https://api.ptpbizintelligence.com/api/web/admin/minas/getContenido/${contenidoId}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    if (response.data.success) {
                        const { menu, titulo, texto, title, text } = response.data.datos;
                        setContenido({ menu, titulo, texto, title, text });
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el contenido:', error);
                    toast.error('Error al obtener el contenido');
                });
        }
    }, [contenidoId, token]);

    const handleInputChange = (name, value) => {
        setContenido(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditorChange = useMemo(() => (name) => (content) => {
        setContenido(prev => ({
            ...prev,
            [name]: content
        }));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const endpoint = contenidoId
            ? `https://api.ptpbizintelligence.com/api/web/admin/minas/updateContenido/${contenidoId}`
            : 'https://api.ptpbizintelligence.com/api/web/admin/minas/createContenido';

        const method = contenidoId ? 'put' : 'post';

        /*
        const dataToSend = {
            ...contenido,
            categoria: contenido.menu,
            pagina: contenido.titulo
        };
        */
        const dataToSend = {
            menu: contenido.menu,
            titulo: contenido.titulo,
            texto: contenido.texto,
            title: contenido.title,
            text: contenido.text
        };

        console.log('Data being sent:', dataToSend); // Para depuración

        try {
            await axios[method](endpoint, dataToSend, {
                headers: { Authorization: `Bearer ${token}` }
            });
            toast.success(contenidoId ? 'Contenido actualizado con éxito' : 'Contenido creado con éxito');
        } catch (error) {
            console.error('Error al guardar el contenido:', error);
            toast.error('Error al guardar el contenido');
        } finally {
            setIsSubmitting(false);
        }
    };

    

    return (
        <LayoutMain title="Contenido">
            <ToastContainer />
            <div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                                {contenidoId ? 'Editar Contenido' : 'Agregar Contenido'}
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/contenidos">Contenidos</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    {contenidoId ? 'Editar' : 'Agregar'}
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/minas-contenidos" className="btn btn-sm btn-flex btn-secondary px-4 py-2">
                                <i className="ki-outline ki-arrow-left fs-7 me-1"></i>Volver
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="row g-7">
                        <div className="col-xl-12">
                            <div className="card card-flush h-lg-100" id="kt_contacts_main">
                                <div className="card-body pt-5">
                                    <form onSubmit={handleSubmit} className="form">
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">Menú</label>
                                            <MenuSelector
                                                value={contenido.menu}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">Título</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contenido.titulo}
                                                onChange={(e) => handleInputChange('titulo', e.target.value)}
                                            />
                                        </div>

                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">Texto (Español)</label>
                                            <CustomEditor
                                                value={contenido.texto}
                                                onChange={handleEditorChange('texto')}
                                            />
                                        </div>

                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">Title (English)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contenido.title}
                                                onChange={(e) => handleInputChange('title', e.target.value)}
                                            />
                                        </div>

                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">Text (English)</label>
                                            <CustomEditor
                                                value={contenido.text}
                                                onChange={handleEditorChange('text')}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <Link to="/contenidos" className="btn btn-light me-3">
                                                Cancelar
                                            </Link>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                {isSubmitting ? 'Guardando...' : 'Guardar'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutMain>
    );
}

export default MinasContenido;