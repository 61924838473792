import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form, Table } from 'react-bootstrap';

function BannersPage(){
	const token = localStorage.getItem('jwtToken');
    const { id } = useParams();
    const [banners, setBanners] = useState([]);
	const [search, setSearch] = useState("");
    const [availableYears, setAvailableYears] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedBannerId, setselectedBannerId] = useState(null);

    const [showModal, setShowModal] = useState(false); // Controlar la visibilidad del modal
    const [isEditMode, setIsEditMode] = useState(false); // Diferenciar entre agregar y editar
    const [editRecordId, setEditRecordId] = useState(null);
	const [originalData, setOriginalData] = useState({ banner_imagen: ''});
	
    const initialState = {
        banner_nombre: '',
        banner_url: '',
        banner_orden: 0,
        banner_cliente_ruc: ''
      };
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
		// Llamar a fetchUsuarios solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if(search.length > 2 || search.length === 0) {
            fetchBanners();
        }
	}, [search,  paginaActual, itemsPorPagina]);

	const fetchBanners = async () => {
        let queryParams = {
            page: paginaActual,
            'per-page': itemsPorPagina,
        };
    
        if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `https://api.ptpbizintelligence.com/api/web/admin/bannersList?${queryString}`;
    
        try {
            const response = await axios.get(url,{
				headers: {
					Authorization: `Bearer ${token}`  // Añadir el token JWT en el encabezado Authorization
				},
			});
            setBanners(response.data.banners); // Asegúrate de que este campo coincida con la respuesta de tu API
            setTotalRegistros(response.data.total);
            setTotalPaginas(response.data.totalPaginas); // Asegúrate de que este campo coincida con la respuesta de tu API
            console.log(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los datos de los banners:', error);
        }
    };
	
    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
        // Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}

	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalRegistros ? totalRegistros : fin; // Asegurarse de no superar el total de usuarios
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalRegistros) {
			fin = totalRegistros;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalRegistros > fin && <>de <b>{totalRegistros}</b></>} banners
			</div>
			)
	};

    const deleteRegistro = async () => {
        setShowDeleteConfirm(false); // Cierra el modal de confirmación
    
        try {
            // Asegúrate de cambiar la URL y el endpoint según tu configuración de API
            await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/deleteBanner/${selectedBannerId}`,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				},
			});
            
            toast.success('Banner eliminado con éxito');
            fetchBanners(); // Asumiendo que tienes una función para recargar los estados financieros
    
        } catch (error) {
            toast.error('Error al eliminar el banner');
        }
    };

    const confirmDelete = (banner_id) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setselectedBannerId(banner_id); // Asegúrate de tener este estado definido para usarlo luego
        setShowDeleteConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirm delete for banner ID:', banner_id);
    };

	const openModalForCreate = () => {
        setIsEditMode(false);
        

		setFormData({
            banner_nombre: '',
            banner_imagen: '',
            banner_url: '',
            banner_cliente_ruc: '',
            banner_orden: '',
        });

		setOriginalData({
			banner_imagen: '',
		});
    
    
        setShowModal(true);
    };

	const closeModal = () => {
        setShowModal(false);
    };

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({ ...prev, [name]: value }));
	};
	
	const handleFileChange = (e) => {
		const { name, files } = e.target;
		if (files.length > 0) {
			const file = files[0];
	
			// Actualiza el estado con el archivo seleccionado
			setFormData(prev => ({
				...prev,
				[name]: file,
			}));
		}
	};
	
	
	/*
	const handleFileChange = (e) => {
		const { name } = e.target;
		setFormData(prev => ({ ...prev, [name]: e.target.files[0] }));
	};*/
	
	/*
	const handleSubmit = async () => {
		const formDataForApi = new FormData();
		Object.keys(formData).forEach(key => {
			if (key === 'db_banners') {
				// Convertir el valor de db_banners a booleano antes de enviar
				formDataForApi.append(key, formData[key] === 'true');
			} else {
				formDataForApi.append(key, formData[key]);
			}
		});
	
		// Determina la URL y el método HTTP según si estamos agregando o editando
		const url = isEditMode
			? `https://api.ptpbizintelligence.com/api/web/admin/editarDigitalBook/${selectedBannerId}`
			: 'https://api.ptpbizintelligence.com/api/web/admin/crearKioskoPublicacion';
		const method = 'POST'; // Usar POST tanto para crear como para editar
	
		try {
			const response = await axios({
				method,
				url,
				data: formDataForApi,
				headers: { 'Content-Type': 'multipart/form-data' },
			});
	
			if (response.data.success) {
				toast.success(`Publicación ${isEditMode ? 'editada' : 'creada'} con éxito`);
				// Aquí podrías cerrar el modal y actualizar la lista de banners
				fetchBanners();
				closeModal();
			}
		} catch (error) {
			console.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`, error);
			toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`);
		}
	};
	*/
	const handleSubmit = async () => {
		const formDataForApi = new FormData();
		Object.keys(formData).forEach(key => {
			// Asegúrate de manejar correctamente los archivos
			if (key === 'banner_imagen') {
				if (formData[key] instanceof File) {
					formDataForApi.append(key, formData[key]);
				}
			} else {
				formDataForApi.append(key, formData[key]);
			}
		});
	
		if (!isEditMode) {
			formDataForApi.append('banner_id', id); // Asegúrate de que el nombre del campo coincida con lo que espera tu backend
		}

		const url = isEditMode
			? `https://api.ptpbizintelligence.com/api/web/admin/editarBanner/${selectedBannerId}`
			: 'https://api.ptpbizintelligence.com/api/web/admin/crearBanner';
		const method = 'POST'; // Usar POST tanto para crear como para editar
	
		try {
			const response = await axios({
				method,
				url,
				data: formDataForApi,
				headers: { 
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`
				},
			});

			if (response.data.success) {
				toast.success(`Banner ${isEditMode ? 'editada' : 'creada'} con éxito`);
				fetchBanners(); // Actualiza la lista de banners
				closeModal(); // Cierra el modal
			}
		} catch (error) {
			console.error(`Error al ${isEditMode ? 'editar' : 'crear'} la banner`, error);
			toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} la banner`);
		}
	};
	

	//editar
	const openModalForEdit = async (bannerId) => {
        setIsEditMode(true);
        setselectedBannerId(bannerId);
        console.log('banner Id:',bannerId);
        // Aquí, asumimos que tienes una función para obtener los detalles de un estado financiero por su ID
        try {
          const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getBanner/${bannerId}`,{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			},
		  });
          const data = response.data;
            console.log('banner edit:',response.data);
          // Suponiendo que `data` contiene todos los campos necesarios
          // Actualiza el estado del formulario con estos datos
            setFormData({
				banner_nombre: data.banner_nombre,
				banner_url: data.banner_url,
				banner_orden: data.banner_orden,
				banner_cliente_ruc: data.banner_cliente_ruc,
				banner_imagen: data.banner_imagen,
          	});

			setOriginalData({
				banner_imagen: data.banner_imagen,
			});
      
          // Si necesitas manejar archivos PDF existentes, considera guardar en el estado la información necesaria para mostrarlos o reemplazarlos
        } catch (error) {
          console.error('Error al recuperar los detalles del banner', error);
          toast.error('Error al cargar los datos para edición');
        }
      
        setShowModal(true);// Asumiendo que esta función abre el modal
    };

	

    return(
        <LayoutMain title="Banners Anuncios">
			<ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteRegistro()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este banner.
			</SweetAlert>
			)}
            {/* begin::Toolbar */}
            	<div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
					{/* begin::Toolbar container */}
					<div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
						{/* begin::Toolbar wrapper */}
						<div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
							{/* begin::Page title */}
							<div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
								{/* begin::Title */}
								<h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Banners Anuncios</h1>
								{/* end::Title */}
								{/* begin::Breadcrumb */}
								<ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Top Online</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Banners Anuncios</li>
									{/* end::Item */}
								</ul>
								{/* end::Breadcrumb */}
							</div>
							{/* end::Page title */}
							{/* begin::Actions */}
							<div  className="d-flex align-items-center gap-2 gap-lg-3">
								{/*
								<a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
								<a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
								<i  className="ki-outline ki-eye fs-4"></i>Preview</a>
								*/}
								<a  className="btn btn-sm btn-flex btn-primary" onClick={()=>openModalForCreate()} ><i  className="ki-outline ki-slider-vertical-2 fs-4"></i>Agregar Banner</a> 
							</div>
							{/* end::Actions */}
						</div>
						{/* end::Toolbar wrapper */}
					</div>
					{/* end::Toolbar container */}
				</div>
				{/* end::Toolbar */}
				{/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                            <th  className="min-w-125px"></th>
                                            <th  className="min-w-125px">Nombre</th>
											<th  className="min-w-125px">Enlace (URL)</th>
                                            <th  className="min-w-125px">RUC del Cliente </th>
                                            <th  className="min-w-125px">Orden</th>
											<th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{banners.map((banner, index) => (
											<tr key={index}>
                                                <td><a href={`https://ptpbizdocs.s3.amazonaws.com/banners/${banner.banner_imagen}`} target="_blank"><img className="adminBannerImagenList" src={`https://ptpbizdocs.s3.amazonaws.com/banners/${banner.banner_imagen}`} /></a></td>
												<td>{banner.banner_nombre}</td>
												<td><a target="_blank" href={banner.banner_url}>Clic aquí</a></td>
                                                <td>{banner.banner_cliente_ruc}</td>
                                                <td>{banner.banner_orden}</td>
                                                <td>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar" onClick={()=>openModalForEdit(banner.banner_id)}><i class="ki-outline ki-notepad-edit"></i></button>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(banner.banner_id)}><i class="ki-outline ki-trash"></i></button>
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>

				<Modal show={showModal} onHide={closeModal}>
					<Modal.Header closeButton>
						<Modal.Title>{isEditMode ? 'Editar Banner' : 'Agregar Banner'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3" controlId="formNombre">
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type="text"
									placeholder="Nombre"
									name="banner_nombre"
									value={formData.banner_nombre || ''}
									onChange={handleChange}
									required // Hace este campo requerido
								/>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formTitulo">
								<Form.Label>Enlace</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enlace https:// o http://"
									name="banner_url"
									value={formData.banner_url || ''}
									onChange={handleChange}
									
								/>
							</Form.Group>

                            <Form.Group className="mb-3" controlId="formClienteRuc">
								<Form.Label>RUC del cliente</Form.Label>
								<Form.Control
									type="text"
									placeholder="# RUC del cliente"
									name="banner_cliente_ruc"
									value={formData.banner_cliente_ruc || ''}
									onChange={handleChange}
									
								/>
							</Form.Group>

							{isEditMode && (
								<Form.Group className="mb-3" controlId="formOrden">
									<Form.Label>Orden</Form.Label>
									<Form.Control
									type="number"
									placeholder="Orden"
									name="banner_orden"
									value={formData.banner_orden || 0}
									onChange={handleChange}
									required={!isEditMode} // Hace este campo requerido
									/>
								</Form.Group>
							)}

							<Form.Group className="mb-3" controlId="formPortada">
								<Form.Label>Imagen del Banner<br/><small>(984px x 170px) (PNG | JPG | GIF) (Máx 3mb)</small></Form.Label>
								{originalData.banner_imagen && (
									<div className="mb-2">
										<a href={`https://ptpbizdocs.s3.amazonaws.com/banners/${originalData.banner_imagen}`} target="_blank"><img src={`https://ptpbizdocs.s3.amazonaws.com/banners/${originalData.banner_imagen}`} alt="Banner Actual" style={{ maxWidth: '100%',border:'solid #ccc 1px' }} /></a>
									</div>
								)}
								<Form.Control
									type="file"
									name="banner_imagen"
									onChange={handleFileChange}
									required={!isEditMode}
								/>
							</Form.Group>
							
							
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>Cerrar</Button>
						<Button variant="primary" onClick={handleSubmit}>
							{isEditMode ? 'Guardar Cambios' : 'Agregar'}
						</Button>
					</Modal.Footer>
				</Modal>



        </LayoutMain>                    
    )
}

export default BannersPage;