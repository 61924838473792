import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form } from 'react-bootstrap';

function NoticiasPage(){

    const token = localStorage.getItem('jwtToken');
    const [noticias, setNoticias] = useState([]);
	const [search, setSearch] = useState("");
    const [availableYears, setAvailableYears] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedNoticiaId, setSelectedNoticiaId] = useState(null);

    const [showModal, setShowModal] = useState(false); // Controlar la visibilidad del modal
    const [isEditMode, setIsEditMode] = useState(false); // Diferenciar entre agregar y editar
    const [editRecordId, setEditRecordId] = useState(null);
    const initialState = {
        fecha: '',
        nombre: '',
        descripcion: '',
        enlace:'',
        tags: ''
        // Asumiendo que inicialmente no hay años disponibles, se añadirán dinámicamente más adelante
      };
    const [formData, setFormData] = useState(initialState);
	  	 
    useEffect(() => {
		// Llamar a fetchNoticias solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if(search.length > 2 || search.length === 0) {
            fetchNoticias();
        }
	}, [search,  paginaActual, itemsPorPagina]);

	const fetchNoticias = async () => {
        let queryParams = {
            page: paginaActual,
            'per-page': itemsPorPagina,
        };
    
        if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `https://api.ptpbizintelligence.com/api/web/admin/noticiasList?${queryString}`;
    
        try {
            const response = await axios.get(url,{
                headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
            });
            setNoticias(response.data.noticias); // Asegúrate de que este campo coincida con la respuesta de tu API
            setTotalRegistros(response.data.total);
            setTotalPaginas(response.data.totalPaginas); // Asegúrate de que este campo coincida con la respuesta de tu API
            console.log(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los datos de los noticias:', error);
        }
    };
	
    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
        // Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}

	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalRegistros ? totalRegistros : fin; // Asegurarse de no superar el total de noticias
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalRegistros) {
			fin = totalRegistros;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalRegistros > fin && <>de <b>{totalRegistros}</b></>} noticias
			</div>
			)
	};

	const confirmDelete = (noticia_id) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setSelectedNoticiaId(noticia_id); // Asegúrate de tener este estado definido para usarlo luego
        setShowDeleteConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirm delete for noticia ID:', noticia_id);
    };
	  
	

    const openModalForCreate = () => {
        setIsEditMode(false);
        // Inicializa un objeto para los campos de archivos PDF basado en availableYears
        setFormData({
            fecha: '',
            nombre: '',
            descripcion: '',
            enlace:'',
            tags: ''
        });
    
        setShowModal(true);
    };
      
    const openModalForEdit = async (noticiaId) => {
        setIsEditMode(true);
        setSelectedNoticiaId(noticiaId);
        console.log('noticiaId:',noticiaId); 
        // Aquí, asumimos que tienes una función para obtener los detalles de un noticia por su ID
        try {
          const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getNoticia/${noticiaId}`,{
            headers: {
                Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
            },
          });
          const data = response.data;
            console.log('noticia:',response.data);
          // Suponiendo que `data` contiene todos los campos necesarios


          setFormData({
            nombre: data.nombre,
            descripcion: data.descripcion,
            enlace: data.enlace,
            tags: data.tags,
          });
      
          // Si necesitas manejar archivos PDF existentes, considera guardar en el estado la información necesaria para mostrarlos o reemplazarlos
        } catch (error) {
          console.error('Error al recuperar los detalles del noticia', error);
          toast.error('Error al cargar los datos para edición');
        }
      
        setShowModal(true);// Asumiendo que esta función abre el modal
    };
      
    const closeModal = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };


    const handleSubmit = async () => {
        // Crea un objeto FormData y agrega los campos del formulario
        const formDataForApi = new FormData();
        Object.keys(formData).forEach(key => {
            formDataForApi.append(key, formData[key]);
        });
        
    
        // Determina la URL y el método HTTP según si estamos agregando o editando
        const url = isEditMode
            ? `https://api.ptpbizintelligence.com/api/web/admin/editarNoticia/${selectedNoticiaId}`
            : 'https://api.ptpbizintelligence.com/api/web/admin/crearNoticia';
        const method = isEditMode ? 'POST' : 'POST'; // Asume que para editar se usa el método PATCH
    
        try {
            // Solicitud HTTP para crear o editar el noticia
            const response = await axios({
                method,
                url,
                data: formDataForApi,
                headers: { 
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${token}`
                        },
            });
    
            // Si la operación es exitosa, muestra un mensaje y actualiza la lista
            console.log(response.data);
            if(response.data.success == true)
            {
                toast.success(`Noticia ${isEditMode ? 'editado' : 'creado'} con éxito`);
                fetchNoticias(); // Refresca la lista
                closeModal(); // Cierra el modal
            }
            
        } catch (error) {
            console.error(`Error al ${isEditMode ? 'editar' : 'crear'} el Noticia`, error);
            toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} el Noticia`);
        }
    };
    
      
    
      
      
    const deleteRegistro = async () => {
        setShowDeleteConfirm(false); // Cierra el modal de confirmación
    
        try {
            // Asegúrate de cambiar la URL y el endpoint según tu configuración de API
            await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/deleteNoticia/${selectedNoticiaId}`,{
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });
            
            toast.success('Estado financiero eliminado con éxito');
            fetchNoticias(); // Asumiendo que tienes una función para recargar los noticias
    
        } catch (error) {
            toast.error('Error al eliminar el noticia');
        }
    };

    

    return(
        <LayoutMain title="Noticias">
            <ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteRegistro()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar este noticia.
			</SweetAlert>
			)}
            
                <div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
                    {/* begin::Toolbar container */}
                    <div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
                        {/* begin::Toolbar wrapper */}
                        <div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                            {/* begin::Page title */}
                            <div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                                {/* begin::Title */}
                                <h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Noticias</h1>
                                {/* end::Title */}
                                {/* begin::Breadcrumb */}
                                <ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item text-muted">Top Online</li>
                                    {/* end::Item */}
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item">
                                        <span  className="bullet bg-gray-500 w-5px h-2px"></span>
                                    </li>
                                    {/* end::Item */}
                                    {/* begin::Item */}
                                    <li  className="breadcrumb-item text-muted">Noticias</li>
                                    {/* end::Item */}
                                </ul>
                                {/* end::Breadcrumb */}
                            </div>
                            {/* end::Page title */}
                            {/* begin::Actions */}
                            <div  className="d-flex align-items-center gap-2 gap-lg-3">
                                {/*
                                <a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
                                <a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                <i  className="ki-outline ki-eye fs-4"></i>Preview</a>
                                */}
                                <button onClick={()=>openModalForCreate()} className="btn btn-sm btn-flex btn-primary">
                                <i  className="ki-outline ki-document fs-4"></i>Agregar</button>
                            </div>
                            {/* end::Actions */}
                        </div>
                        {/* end::Toolbar wrapper */}
                    </div>
                    {/* end::Toolbar container */}
                </div>
                {/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							{/* begin::Card header */}
							<div  className="card-header border-0 pt-6">
								{/* begin::Card title */}
								<div  className="card-title">
									{/* begin::Search */}
									<div  className="d-flex align-items-center position-relative my-1">
										<i  className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
										<input type="text" data-kt-customer-table-filter="search"  className="form-control form-control-solid w-350px ps-12 tablaBuscador" placeholder="Buscar Título, Descripción y Tags"  value={search} onChange={(e) => setSearch(e.target.value)} />
									</div>
									{/* end::Search */}
									
								</div>
								{/* begin::Card title */}
								
							</div>
							{/* end::Card header */}
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
											<th  className="min-w-25px">ID</th>
											<th  className="min-w-125px">Título</th>
											<th  className="min-w-125px">Descripción</th>
                                            <th  className="min-w-125px">Tags</th>
                                            <th  className="min-w-125px">Enlace</th>
                                            <th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{noticias.map((noticia, index) => (
											<tr key={index}>
												<td>{noticia.noticia_id}</td>
												<td>{noticia.nombre}</td>
												<td>{noticia.descripcion}</td>
                                                <td className="noticiaTagsTd">{noticia.tags}</td>
                                                <td><a target="_blank" href={noticia.enlace}>Ver noticia</a></td>
                                                <td>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar" onClick={()=>openModalForEdit(noticia.noticia_id)}><i class="ki-outline ki-notepad-edit"></i></button>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(noticia.noticia_id)}><i class="ki-outline ki-trash"></i></button>
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Editar Noticia' : 'Agregar Noticia'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formNombre">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Título"
                                    name="nombre"
                                    value={formData.nombre || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDescripcion">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    as="textarea" // Cambio clave aquí
                                    placeholder="Descripción"
                                    name="descripcion"
                                    value={formData.descripcion || ''}
                                    onChange={handleChange}
                                    rows={3} // Puedes ajustar el número de filas según necesites
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEnlace">
                                <Form.Label>Enlace</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enlace en formato completo https://"
                                    name="enlace"
                                    value={formData.enlace || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEtiquetas">
                                <Form.Label>Etiquetas</Form.Label>
                                <Form.Control
                                    as="textarea" // Cambio clave aquí
                                    placeholder="Etiquetas separadas por comas"
                                    name="tags"
                                    value={formData.tags || ''}
                                    onChange={handleChange}
                                    rows={2} // Ajusta las filas según sea necesario
                                />
                            </Form.Group>

                        </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                            </Button>
                        </Modal.Footer>
                </Modal>

			
        </LayoutMain>
    )

}

export default NoticiasPage;