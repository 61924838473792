import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext'; // Asegúrate de que la ruta sea correcta

const LogoutPage = () => {
    const { setUserData } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Aquí colocas la lógica de cierre de sesión
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userData');
        setUserData({}); // Reinicia el estado de userData

        // Redirigir al usuario al login
        navigate('/login');
    }, [navigate, setUserData]);

    return (
        <div>Cerrando sesión...</div>
    );
};

export default LogoutPage;