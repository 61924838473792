import React from "react";
import LayoutMain from '../../components/LayoutMain';

function MinasPage(){


    return(
        <LayoutMain title="Podcast">
        Minas
        </LayoutMain>
    )

}

export default MinasPage;