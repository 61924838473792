import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import LayoutMain from '../../components/LayoutMain';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PerfilGaleriaPage() {
    const token = localStorage.getItem('jwtToken');
    const { perfilId } = useParams();
    const [galeriaFotos, setGaleriaFotos] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (perfilId) {
            axios.get(`https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraPerfilGaleriaFotos/${perfilId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    if (response.data.success) {
                        setGaleriaFotos(response.data.galeria_fotos);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener la galería:', error);
                    toast.error('Error al obtener la galería');
                });
        }
    }, [perfilId]);

    const onDrop = acceptedFiles => {
        const formData = new FormData();
        formData.append('perfilId', perfilId);
        acceptedFiles.forEach(file => {
            formData.append('files[]', file); // Use 'files[]' to ensure multiple files are sent correctly
        });

        setIsSubmitting(true);

        axios.post(`https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraPerfilGaleriaSubirFotos/${perfilId}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                if (response.data.success) {
                    setGaleriaFotos(response.data.galeria_fotos);
                    toast.success('Fotos subidas con éxito');
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => {
                console.error('Error al subir las fotos:', error);
                toast.error('Error al subir las fotos');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleDelete = (fileName) => {
        axios.post(`https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraPerfilGaleriaEliminarFoto`, {
            perfilId,
            fileName
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                if (response.data.success) {
                    setGaleriaFotos(response.data.galeria_fotos);
                    toast.success('Foto eliminada con éxito');
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => {
                console.error('Error al eliminar la foto:', error);
                toast.error('Error al eliminar la foto');
            });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <LayoutMain title="Galería de Fotos">
            <ToastContainer />
            <div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                                {perfilId ? 'Editar Galería de Fotos' : 'Agregar Galería de Fotos'}
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                <li className="breadcrumb-item text-muted">Infraestructura</li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/infraestructura-perfiles">Perfiles</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Galería de Fotos</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/infraestructura-perfiles" className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body">
                                <i className="ki-outline ki-black-left fs-4"></i>Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="row g-7">
                        <div className="col-xl-10 col-xxl-10">
                            <div className="card card-flush h-lg-100" id="kt_contacts_main">
                                <div className="card-body pt-5">
                                    <div {...getRootProps()} className="dropzone mb-7">
                                        <input {...getInputProps()} />
                                        <p>Arrastra y suelta algunas fotos aquí, o haz clic para seleccionar fotos</p>
                                    </div>
                                    {isSubmitting && <p>Subiendo fotos...</p>}
                                    <div className="galeria">
                                        {Array.isArray(galeriaFotos) && galeriaFotos.map((foto, index) => (
                                            <div key={index} className="foto-item">
                                                <img src={foto.url} alt={`Foto ${index}`} className="img-thumbnail" />
                                                <button onClick={() => handleDelete(foto.fileName)} className="btn btn-danger mt-2">Eliminar</button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-end mt-5">
                                        <button type="button" className="btn btn-light me-3" onClick={() => window.history.back()}>Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutMain>
    );
}

export default PerfilGaleriaPage;
