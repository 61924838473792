import React from "react";
import LayoutMain from '../../components/LayoutMain';

function PodcastPage(){


    return(
        <LayoutMain title="Podcast">
        Podcast
        </LayoutMain>
    )

}

export default PodcastPage;