import React from "react";
import LayoutMain from '../../components/LayoutMain';

function InfraestructuraPage(){


    return(
        <LayoutMain title="Podcast">
        Infraestructura
        </LayoutMain>
    )

}

export default InfraestructuraPage;