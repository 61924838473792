import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PerfilMapaPage() {
    const token = localStorage.getItem('jwtToken');
    const { perfilId } = useParams();
    const [perfil, setPerfil] = useState({
        map_pdf: '',
        excel: '',
        txt_pdf: '',
        prefactibilidad: '',
        factibilidad: '',
        mineral: '',
        tipo: '',
        latitud: '0.0',
        longitud: '0.0'
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mapImage, setMapImage] = useState(null);

    useEffect(() => {
        if (perfilId) {
            axios.get(`https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraPerfilMapa/${perfilId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    if (response.data.success) {
                        const perfilData = response.data.datos;
                        // Asegurarse de que los valores no sean null
                        for (const key in perfilData) {
                            if (perfilData[key] === null) {
                                perfilData[key] = '';
                            }
                        }
                        setPerfil(perfilData);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el perfil:', error);
                    toast.error('Error al obtener el perfil');
                });
        }
    }, [perfilId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPerfil(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setMapImage(e.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const endpoint = `https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraUpdatePerfilMapa/${perfilId}`;

        const formData = new FormData();
        for (const key in perfil) {
            formData.append(key, perfil[key] === '' ? '' : perfil[key]);
        }
        if (mapImage) {
            formData.append('map_image', mapImage);
        }

        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            if (response.data.success) {
                toast.success('Perfil actualizado con éxito');
            } else {
                toast.error('Error al guardar el perfil');
                console.error('Errores:', response.data.errors);
            }
        } catch (error) {
            console.error('Error al guardar el perfil:', error);
            toast.error('Error al guardar el perfil');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <LayoutMain title="Mapa del Perfil">
            <ToastContainer />
            <div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                                {perfilId ? 'Editar Mapa del Perfil' : 'Agregar Mapa del Perfil'}
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                <li className="breadcrumb-item text-muted">Infraestructura</li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/infraestructura-perfiles">Perfiles</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Mapa del Perfil</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/infraestructura-perfiles" className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body">
                                <i className="ki-outline ki-black-left fs-4"></i>Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="row g-7">
                        <div className="col-xl-10 col-xxl-10">
                            <div className="card card-flush h-lg-100" id="kt_contacts_main">
                                <div className="card-body pt-5">
                                    <form onSubmit={handleSubmit} className="form">
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Imagen del Mapa</span>
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="map_image"
                                                onChange={handleFileChange}
                                            />
                                            {perfilId && (
                                                <div className="mt-3">
                                                    <img src={`https://ptpbizdocs.s3.amazonaws.com/infraestructura/images/mapa_${perfilId}.jpg`} alt="Imagen del Mapa" style={{ width: '600px' }} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>URL PDF</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="map_pdf"
                                                value={perfil.map_pdf}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Texto PDF</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="txt_pdf"
                                                value={perfil.txt_pdf}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Excel</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="excel"
                                                value={perfil.excel}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Prefactibilidad</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="prefactibilidad"
                                                value={perfil.prefactibilidad}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Factibilidad</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="factibilidad"
                                                value={perfil.factibilidad}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Mineral</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mineral"
                                                value={perfil.mineral}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Tipo</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="tipo"
                                                value={perfil.tipo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Latitud</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="latitud"
                                                value={perfil.latitud}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fv-row mb-7">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span>Longitud</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="longitud"
                                                value={perfil.longitud}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light me-3">Cancelar</button>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <>
                                                        Por favor espera...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </>
                                                ) : (
                                                    "Guardar"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutMain>
    );
}

export default PerfilMapaPage;
