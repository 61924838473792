import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from '../../assets/media/datetime/calendar.png'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form, Table } from 'react-bootstrap';

function KioskoPage(){

	const token = localStorage.getItem('jwtToken');
    const [publicaciones, setPublicaciones] = useState([]);
	const [search, setSearch] = useState("");
    const [availableYears, setAvailableYears] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedPublicacionId, setSelectedPublicacionId] = useState(null);
    const [selectedDeletePDF, setSelectedDeletePDF] = useState(null);
    const [selectedDeleteYearPDF, setSelectedDeleteYearPDF] = useState(null);
    const [showDeletePdfConfirm, setShowDeletePdfConfirm] = useState(false);

    const [showModal, setShowModal] = useState(false); // Controlar la visibilidad del modal
    const [isEditMode, setIsEditMode] = useState(false); // Diferenciar entre agregar y editar
    const [editRecordId, setEditRecordId] = useState(null);
	const [originalData, setOriginalData] = useState({ db_portada: '', db_pdf: '' });
	

    const initialState = {
        db_nombre: '',
        db_titulo: '',
		db_seccion: '',
        archivosPDF: {}
        // Asumiendo que inicialmente no hay años disponibles, se añadirán dinámicamente más adelante
      };
    const [formData, setFormData] = useState(initialState);

	const initialStateCategorias = {
        db_nombre: '',
        db_titulo: '',
		db_seccion: '',
        archivosPDF: {}
        // Asumiendo que inicialmente no hay años disponibles, se añadirán dinámicamente más adelante
      };
	const [formDataCategorias, setFormDataCategorias] = useState(initialStateCategorias);
	const [showCategoriasModal, setShowCategoriasModal] = useState(false);
	const [categorias, setCategorias] = useState([]);

	  	 
    useEffect(() => {
		// Llamar a fetchUsuarios solo si search tiene más de 2 caracteres o al cambiar otros filtros
		if(search.length > 2 || search.length === 0) {
            fetchPublicaciones();
        }
	}, [search,  paginaActual, itemsPorPagina]);

	const fetchPublicaciones = async () => {
        let queryParams = {
            page: paginaActual,
            'per-page': itemsPorPagina,
        };
    
        if (search.length > 2) queryParams.search = search; // Solo añadir si la búsqueda tiene más de 2 caracteres
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `https://api.ptpbizintelligence.com/api/web/admin/kioskoPublicacionesList?${queryString}`;
    
        try {
            const response = await axios.get(url,{
				headers: {
					Authorization: `Bearer ${token}`  // Añadir el token JWT en el encabezado Authorization
				}
			});
            setPublicaciones(response.data.publicaciones); // Asegúrate de que este campo coincida con la respuesta de tu API
            setTotalRegistros(response.data.total);
            setTotalPaginas(response.data.totalPaginas); // Asegúrate de que este campo coincida con la respuesta de tu API
            setAvailableYears(response.data.availableYears); // Asume que este campo viene en la respuesta de la API
            console.log(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los datos de los estados financieros:', error);
        }
    };
	
    const cambiarItemsPorPagina = (e) => {
        setItemsPorPagina(e.target.value);
        setPaginaActual(1); // Vuelve a la primera página con el nuevo tamaño de página
    };

    const cambiarPagina = (nuevaPagina) => {
        setPaginaActual(nuevaPagina);
    };

	const renderizarBotonesPaginacion = () => {
		let rangoPaginas = [];
		const paginaMax = 3; // Máximo de páginas antes y después de la página actual
	
        // Añadir la primera página
		if (paginaActual > 1 + paginaMax) {
			rangoPaginas.push(1);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual > 2 + paginaMax) {
			rangoPaginas.push("...");
		}
	
		// Generar el rango de páginas alrededor de la página actual
		for (let i = Math.max(1, paginaActual - paginaMax); i <= Math.min(paginaActual + paginaMax, totalPaginas); i++) {
			rangoPaginas.push(i);
		}
	
		// Añadir "..." para indicar un salto si es necesario
		if (paginaActual < totalPaginas - (1 + paginaMax)) {
			rangoPaginas.push("...");
		}
	
		// Añadir la última página
		if (paginaActual < totalPaginas - paginaMax) {
			rangoPaginas.push(totalPaginas);
		}

	
		return (
			<div>
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual - 1)} disabled={paginaActual === 1}>Anterior</a>
				{rangoPaginas.map((pagina, index) => (
					<a
						href="#"
						className="tablaPaginacionNumeracion"
						key={index}
						onClick={(e) => {
							e.preventDefault();
							if (pagina !== "...") {
								cambiarPagina(pagina);
							}
						}}
						style={{ fontWeight: paginaActual === pagina ? "bold" : "normal", color: paginaActual === pagina ? '#000' : 'rgb(255,111,30)' }}
					>
						{pagina}
					</a>
				))}
				<a class="tablaPaginacionBtn" href="#" onClick={() => cambiarPagina(paginaActual + 1)} disabled={paginaActual === totalPaginas}>Siguiente</a>
			</div>
		);
	};
	
	const calcularRangoMostrado = () => {
		// Asegurarse de que paginaActual y itemsPorPagina son tratados como números
		const inicio = ((paginaActual - 1) * itemsPorPagina) + 1;
		let fin = paginaActual * itemsPorPagina;
		fin = fin > totalRegistros ? totalRegistros : fin; // Asegurarse de no superar el total de usuarios
	
		// Si estás en la última página y no se llenan todos los ítems por página
		if(fin > totalRegistros) {
			fin = totalRegistros;
		}
	
		return (
			<div className="tablaPaginacionCantidadRangoMostrado">
			Mostrando <b>{inicio}</b> a <b>{fin}</b> {totalRegistros > fin && <>de <b>{totalRegistros}</b></>} publicaciones
			</div>
			)
	};

    const deleteRegistro = async () => {
        setShowDeleteConfirm(false); // Cierra el modal de confirmación
    
        try {
            // Asegúrate de cambiar la URL y el endpoint según tu configuración de API
            await axios.delete(`https://api.ptpbizintelligence.com/api/web/admin/deleteKioskoPublicacion/${selectedPublicacionId}`,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
			});
            
            toast.success('Publicación eliminada con éxito');
            fetchPublicaciones(); // Asumiendo que tienes una función para recargar los estados financieros
    
        } catch (error) {
            toast.error('Error al eliminar la publicación');
        }
    };

    const confirmDelete = (publicacion_id) => {
        // Aquí puedes configurar el estado para mostrar un modal de confirmación, por ejemplo
        setSelectedPublicacionId(publicacion_id); // Asegúrate de tener este estado definido para usarlo luego
        setShowDeleteConfirm(true); // Suponiendo que controla la visibilidad de un modal de confirmación
        console.log('Confirm delete for publicacion ID:', publicacion_id);
    };

	const openModalForCreate = () => {
        setIsEditMode(false);
        

		setFormData({
            db_nombre: '',
            db_titulo: '', 
			db_secciones: '',
        });

		setOriginalData({
			db_portada: '',
			db_pdf: '',
		});
    
    
        setShowModal(true);
    };

	const closeModal = () => {
        setShowModal(false);
    };

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({ ...prev, [name]: value }));
	};
	
	const handleFileChange = (e) => {
		const { name, files } = e.target;
		if (files.length > 0) {
			const file = files[0];
	
			// Actualiza el estado con el archivo seleccionado
			setFormData(prev => ({
				...prev,
				[name]: file,
			}));
		}
	};
	
	
	/*
	const handleFileChange = (e) => {
		const { name } = e.target;
		setFormData(prev => ({ ...prev, [name]: e.target.files[0] }));
	};*/
	
	/*
	const handleSubmit = async () => {
		const formDataForApi = new FormData();
		Object.keys(formData).forEach(key => {
			if (key === 'db_secciones') {
				// Convertir el valor de db_secciones a booleano antes de enviar
				formDataForApi.append(key, formData[key] === 'true');
			} else {
				formDataForApi.append(key, formData[key]);
			}
		});
	
		// Determina la URL y el método HTTP según si estamos agregando o editando
		const url = isEditMode
			? `https://api.ptpbizintelligence.com/api/web/admin/editarDigitalBook/${selectedPublicacionId}`
			: 'https://api.ptpbizintelligence.com/api/web/admin/crearKioskoPublicacion';
		const method = 'POST'; // Usar POST tanto para crear como para editar
	
		try {
			const response = await axios({
				method,
				url,
				data: formDataForApi,
				headers: { 'Content-Type': 'multipart/form-data' },
			});
	
			if (response.data.success) {
				toast.success(`Publicación ${isEditMode ? 'editada' : 'creada'} con éxito`);
				// Aquí podrías cerrar el modal y actualizar la lista de publicaciones
				fetchPublicaciones();
				closeModal();
			}
		} catch (error) {
			console.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`, error);
			toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`);
		}
	};
	*/
	const handleSubmit = async () => {
		const formDataForApi = new FormData();
		Object.keys(formData).forEach(key => {
			if (key === 'db_secciones') {
				// Asegura que el valor booleano se envíe correctamente como 'true' o 'false'
				formDataForApi.append(key, formData[key] ? 'true' : 'false');
			} else {
				formDataForApi.append(key, formData[key]);
			}
		});
	
		const url = isEditMode
			? `https://api.ptpbizintelligence.com/api/web/admin/editarKioskoPublicacion/${selectedPublicacionId}`
			: 'https://api.ptpbizintelligence.com/api/web/admin/crearKioskoPublicacion';
		const method = 'POST'; // Usar POST tanto para crear como para editar
	
		try {
			const response = await axios({
				method,
				url,
				data: formDataForApi,
				headers: { 
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}` 
				},
			});
	
			if (response.data.success) {
				toast.success(`Publicación ${isEditMode ? 'editada' : 'creada'} con éxito`);
				fetchPublicaciones(); // Actualiza la lista de publicaciones
				closeModal(); // Cierra el modal
			}
		} catch (error) {
			console.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`, error);
			toast.error(`Error al ${isEditMode ? 'editar' : 'crear'} la publicación`);
		}
	};
	

	//editar
	const openModalForEdit = async (publicacionId) => {
        setIsEditMode(true);
        setSelectedPublicacionId(publicacionId);
        console.log('Publicación Id:',publicacionId);
        // Aquí, asumimos que tienes una función para obtener los detalles de un estado financiero por su ID
        try {
          const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getKioskoPublicacion/${publicacionId}`,{
			headers: {
				Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
			}
		  });
          const data = response.data;
            console.log('publicación edit:',response.data);
          // Suponiendo que `data` contiene todos los campos necesarios
          // Actualiza el estado del formulario con estos datos
         

        	setFormData({
				db_nombre: data.db_nombre,
				db_titulo: data.db_titulo,
				db_secciones: data.db_secciones,
				db_orden: data.db_orden,
				db_portada: data.db_portada,
				db_pdf: data.db_pdf,
				// Asegúrate de manejar los campos de archivos PDF de manera adecuada
				// Es posible que necesites una lógica especial si planeas permitir a los usuarios ver o reemplazar los archivos PDF existentes
          	});

			setOriginalData({
				db_portada: data.db_portada,
				db_pdf: data.db_pdf,
			});
      
          // Si necesitas manejar archivos PDF existentes, considera guardar en el estado la información necesaria para mostrarlos o reemplazarlos
        } catch (error) {
          console.error('Error al recuperar los detalles de la publicación', error);
          toast.error('Error al cargar los datos para edición');
        }
      
        setShowModal(true);// Asumiendo que esta función abre el modal
    };
	
	//categorias
	const openModalForCategories = async (publicacionId) => {
		// Asumiendo que setIsEditMode y setSelectedPublicacionId ya están definidos correctamente.
		setIsEditMode(true);
		setSelectedPublicacionId(publicacionId);
		try {
			// Suponiendo que esta URL te devuelve tanto las categorías vinculadas como todas las categorías disponibles
			const response = await axios.get(`https://api.ptpbizintelligence.com/api/web/admin/getKioskoPublicacionCategorias/${publicacionId}`,{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
			});
			// Suponiendo que `categorias` es la lista completa y `vinculadas` son las ids de las categorías vinculadas
			const categoriasTotales = response.data.categorias;
			const categoriasVinculadas = new Set(response.data.categoriasVinculadas.map(cat => cat.kioskocategoria_id));
	
			const categoriasConCheck = categoriasTotales.map(cat => ({
				...cat,
				checked: categoriasVinculadas.has(cat.kioskocategoria_id),
			}));
	
			setCategorias(categoriasConCheck);
		} catch (error) {
			console.error('Error al recuperar las categorías de la publicación', error);
			toast.error('Error al cargar las categorías para la publicación');
		}
		setShowCategoriasModal(true);
	};
	
	const handleCategoriaChange = (e, id) => {
		// Actualizar el estado para reflejar el cambio en el checkbox seleccionado
		setCategorias(categorias.map(cat => {
			if (cat.kioskocategoria_id === id) {
				return { ...cat, seleccionada: e.target.checked };
			}
			return cat;
		}));
	};
	
	
	
	const closeModalForCategories = () => {
		setShowCategoriasModal(false); // Asume que setShowCategoriasModal es el setter para el estado que controla la visibilidad de la modal de categorías
	};

	const handleSubmitCategorias = async () => {
		// Filtrar para obtener solo las categorías seleccionadas
		const categoriasSeleccionadas = categorias
			.filter(cat => cat.seleccionada)
			.map(cat => cat.kioskocategoria_id);
	
		try {
			// Reemplaza la URL con la ruta correcta de tu API
			const response = await axios.post(`https://api.ptpbizintelligence.com/api/web/admin/updatePublicacionCategorias/${selectedPublicacionId}`, {
				categorias: categoriasSeleccionadas,
			},{
				headers: {
					Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
				}
			});
	
			if (response.data.success) {
				toast.success('Categorías actualizadas con éxito');
				closeModalForCategories(); // Asegúrate de tener esta función definida para cerrar la modal
				// Aquí podrías también actualizar el estado o refrescar datos según sea necesario
			} else {
				toast.error('Error al actualizar categorías');
			}
		} catch (error) {
			console.error('Error al actualizar las categorías:', error);
			toast.error('Error al realizar la petición');
		}
	};
	
	

    return(
        <LayoutMain title="Kiosko">
			<ToastContainer />
			{showDeleteConfirm && (
			<SweetAlert
				warning
				showCancel
				confirmBtnText="Sí, eliminar"
				confirmBtnBsStyle="danger"
				title="¿Estás seguro?"
				onConfirm={() => deleteRegistro()}
				onCancel={() => setShowDeleteConfirm(false)}
				focusCancelBtn
			>
				No podrás recuperar esta publicación.
			</SweetAlert>
			)}
            {/* begin::Toolbar */}
            	<div id="kt_app_toolbar"  className="app-toolbar pt-10 mb-0">
					{/* begin::Toolbar container */}
					<div id="kt_app_toolbar_container"  className="app-container container-fluid d-flex align-items-stretch">
						{/* begin::Toolbar wrapper */}
						<div  className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
							{/* begin::Page title */}
							<div  className="page-title d-flex flex-column justify-content-center gap-1 me-3">
								{/* begin::Title */}
								<h1  className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">Kiosko - Publicaciones</h1>
								{/* end::Title */}
								{/* begin::Breadcrumb */}
								<ul  className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Top Online</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Kiosko</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item">
										<span  className="bullet bg-gray-500 w-5px h-2px"></span>
									</li>
									{/* end::Item */}
									{/* begin::Item */}
									<li  className="breadcrumb-item text-muted">Publicaciones</li>
									{/* end::Item */}
								</ul>
								{/* end::Breadcrumb */}
							</div>
							{/* end::Page title */}
							{/* begin::Actions */}
							<div  className="d-flex align-items-center gap-2 gap-lg-3">
								{/*
								<a href="#"  className="btn btn-sm btn-flex btn-transparent btn-hover-outline" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Save</a>
								<a href=""  className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
								<i  className="ki-outline ki-eye fs-4"></i>Preview</a>
								*/}
								<a  className="btn btn-sm btn-flex btn-primary" onClick={()=>openModalForCreate()} ><i  className="ki-outline ki-book-open fs-4"></i>Agregar Publicación</a> <Link to="/toponline-kiosko-categorias"  className="btn btn-sm btn-flex btn-warning" ><i  className="ki-outline ki-category fs-4"></i>Categorías</Link>
							</div>
							{/* end::Actions */}
						</div>
						{/* end::Toolbar wrapper */}
					</div>
					{/* end::Toolbar container */}
				</div>
				{/* end::Toolbar */}
				{/* begin::Content */}
				<div id="kt_app_content"  className="app-content flex-column-fluid">
					{/* begin::Content container */}
					<div id="kt_app_content_container"  className="app-container container-fluid">
						{/* begin::Card */}
						<div  className="card">
							
							{/* begin::Card body TABLAUSUARIOS */}
							<div className="card-body pt-0">
								<div className="row tablaPaginacionWrapper">
									<div className="tablaPaginacionCantidad col-xl-6">
										<select className="tablaPaginacionCantidadSelector" value={itemsPorPagina} onChange={cambiarItemsPorPagina}>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
										</select>
										{calcularRangoMostrado()}
									</div>
									<div className="tablaPaginacionBotones col-xl-6">
										{renderizarBotonesPaginacion()}
									</div>
								</div>
								{/* begin::Table */}
								<table  className="table align-middle table-row-dashed fs-6 gy-5 tablaPTPAdmin" id="kt_customers_table">
									<thead>
										<tr  className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                            <th  className="min-w-125px"></th>
                                            <th  className="min-w-125px">Título</th>
											<th  className="min-w-125px">PDF</th>
											<th  className="text-center min-w-150px">Acciones</th>
										</tr>
									</thead>
									<tbody  className="fw-semibold text-gray-600">
										{publicaciones.map((publicacion, index) => (
											<tr key={index}>
                                                <td><a href={`https://ptpkioskodigital.s3.amazonaws.com/portadas/${publicacion.db_portada}`} target="_blank"><img className="adminKioskoPortadaList" src={`https://ptpkioskodigital.s3.amazonaws.com/portadas/${publicacion.db_portada}`} /></a></td>
												<td>{publicacion.db_titulo}</td>
												<td>
													{publicacion.db_pdf ? (
														<a href={`https://ptpkioskodigital.s3.amazonaws.com/pdfs/${publicacion.db_pdf}`} target="_blank">PDF</a>
													) : (
														<Link to={`/toponline-kiosko-secciones/${publicacion.db_id}`}>Secciones</Link>
													)
													}
												</td>
												<td>
													{publicacion.db_secciones == true && (
														<button className="menu-link px-3 usuarioPageBtn kioskoPageBtnCategorias" onClick={()=>openModalForCategories(publicacion.db_id)}><i class="ki-outline ki-category"></i></button>
														) 
													}
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEditar" onClick={()=>openModalForEdit(publicacion.db_id)}><i class="ki-outline ki-notepad-edit"></i></button>
													<button className="menu-link px-3 usuarioPageBtn usuarioPageBtnEliminar" onClick={() => confirmDelete(publicacion.db_id)}><i class="ki-outline ki-trash"></i></button>

													
												</td>	
											</tr>
										))}
									</tbody>
								</table>
								{/* end::Table */}
								<div className="tablaPaginacionBotones">
									{renderizarBotonesPaginacion()}
								</div>
							</div>
							{/* end::Card body END TABLAUSUARIOS*/}
						</div>
						{/* end::Card */}
					</div>
					{/* end::Content container */}
				</div>

				<Modal show={showModal} onHide={closeModal}>
					<Modal.Header closeButton>
						<Modal.Title>{isEditMode ? 'Editar Publicación' : 'Agregar Publicación'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3" controlId="formNombre">
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type="text"
									placeholder="Nombre"
									name="db_nombre"
									value={formData.db_nombre || ''}
									onChange={handleChange}
									required // Hace este campo requerido
								/>
							</Form.Group>

							<Form.Group className="mb-3" controlId="formTitulo">
								<Form.Label>Título</Form.Label>
								<Form.Control
									type="text"
									placeholder="Título"
									name="db_titulo"
									value={formData.db_titulo || ''}
									onChange={handleChange}
									required // Hace este campo requerido
								/>
							</Form.Group>

							{isEditMode && (
								<Form.Group className="mb-3" controlId="formOrden">
									<Form.Label>Orden</Form.Label>
									<Form.Control
									type="number"
									placeholder="Orden"
									name="db_orden"
									value={formData.db_orden || 0}
									onChange={handleChange}
									required // Hace este campo requerido
									/>
								</Form.Group>
							)}

							<Form.Group className="mb-3" controlId="formPortada">
								<Form.Label>Portada</Form.Label>
								{originalData.db_portada && (
									<div className="mb-2">
										<img src={`https://ptpkioskodigital.s3.amazonaws.com/portadas/${originalData.db_portada}`} alt="Portada Actual" style={{ maxWidth: '80%' }} />
									</div>
								)}
								<Form.Control
									type="file"
									name="db_portada"
									onChange={handleFileChange}
									required={!isEditMode}
								/>
							</Form.Group>



							<Form.Group className="mb-3" controlId="formSecciones">
								<Form.Label>Secciones</Form.Label>
								<Form.Select
									name="db_secciones"
									value={formData.db_secciones !== undefined ? formData.db_secciones.toString() : ''}
									onChange={(e) => {
										// Si el usuario selecciona "Escoger", no establecer un valor booleano
										const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : '';
										handleChange({ target: { name: e.target.name, value } });
									}}
									required // Hace este campo requerido
								>
									<option value=""> - Escoger - </option>
									<option value="false">No</option>
									<option value="true">Sí</option>
								</Form.Select>
							</Form.Group>


							{formData.db_secciones === false && (
								<Form.Group className="mb-3" controlId="formPDF">
									<Form.Label>PDF</Form.Label>
									{originalData.db_pdf && (
										<div className="mb-3">
											{/* Utiliza originalData para el enlace, asegurando que se muestre el PDF actual hasta que se guarde uno nuevo */}
											<a target="_blank" href={`https://ptpkioskodigital.s3.amazonaws.com/pdfs/${originalData.db_pdf}`}>Ver PDF</a>
										</div>
									)}
									<Form.Control
										type="file"
										name="db_pdf"
										onChange={handleFileChange} // Manejar archivos
										required={formData.db_secciones === 'false'} // Requerido si Secciones es No
									/>
								</Form.Group>
							)}

						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>Cerrar</Button>
						<Button variant="primary" onClick={handleSubmit}>
							{isEditMode ? 'Guardar Cambios' : 'Agregar'}
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showCategoriasModal} onHide={closeModalForCategories}>
					<Modal.Header closeButton>
						<Modal.Title>{isEditMode ? 'Editar Categorías de Publicación' : 'Vincular Categorías'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							{categorias.map((categoria) => (
								<Form.Group className="mb-3" key={categoria.kioskocategoria_id}>
									<Form.Check 
										type="checkbox"
										label={categoria.kioskocategoria_nombre}
										checked={categoria.seleccionada} // Cambiado de 'checked' a 'seleccionada'
										onChange={(e) => handleCategoriaChange(e, categoria.kioskocategoria_id)}
									/>
								</Form.Group>
							))}
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModalForCategories}>Cerrar</Button>
						<Button variant="primary" onClick={handleSubmitCategorias}>
							Guardar Cambios
						</Button>
					</Modal.Footer>
				</Modal>





        </LayoutMain>                    
    )

}

export default KioskoPage;